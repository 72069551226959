import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { withFormik } from "formik";
import InsuranceValidationSchema from "./InsuranceValidationSchema";
import { compose } from "recompose";
import PhonePrefix from "../../components/inputs/PhonePrefix";
import { InsurancePassengerFormWebStyles } from "./styles/InsurancePassengerFormWebStyles";
import {
  IS_B2B,
  IS_CUSTOMER,
  LOGIN_PEOPLE_DETAIL,
  IS_EMPLOYEE,
} from "../../config/constants";
import {
  GetArrangerPhone,
  GetArrangerEmail,
  GetArrangerPhonePrefix,
} from "../../utils/common";
import { replace } from "lodash";
import { isAuthenticated } from "../../lib/auth";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../assets/images/info-icon.svg";
import GrayCircleIcon from "../../assets/images/insider/gray_circle.svg";
import GreenCircleIcon from "../../assets/images/insider/green_circle.svg";
import { Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { checkInApp, checkInWeb, getLocale } from "../../utils/checkurl";
import { PhoneValidation } from "../../utils/phoneValidation";

const InsurancePassengerFormWeb = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  continueLoading,
  setcontinueLoading,
}) => {
  const classes = InsurancePassengerFormWebStyles();
  const [flag, setFlag] = useState(false);
  const isB2B =
    localStorage.getItem(IS_B2B) && localStorage.getItem(IS_B2B) === "yes"
      ? true
      : false;
  const isEmployee =
    localStorage.getItem(IS_EMPLOYEE) &&
    localStorage.getItem(IS_EMPLOYEE) === "yes"
      ? true
      : false;
  const { t } = useTranslation([
    "travelServices",
    "masterPassengerInformation",
    "b2c",
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();
  const navigate = useNavigate();
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const params = useParams();
  const phoneNumberRef = useRef(null);
  const lastNameRef = useRef(null);
  const [selectedLanguage] = useState(getLocale(params.locale));

  useEffect(() => {
    if (!flag) {
      // let { setFieldValue } = props;
      const isCustomer =
        localStorage.getItem(IS_CUSTOMER) &&
        localStorage.getItem(IS_CUSTOMER) === "yes"
          ? true
          : false;
      let strData = localStorage.getItem(LOGIN_PEOPLE_DETAIL)
        ? JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL))
        : null;
      isB2B &&
        strData &&
        setFieldValue("b2bPeopleId", strData ? strData.id : null);

      if (strData) {
        let tmpPhonePrefix =
          strData && strData.arranger_phones
            ? GetArrangerPhonePrefix(strData.arranger_phones)
            : "95";
        let tmpPhone =
          strData && strData.arranger_phones
            ? GetArrangerPhone(strData.arranger_phones)
            : null;
        let tmpFirstName =
          strData && strData.first_name ? strData && strData.first_name : "";
        let tmpLastName =
          strData && strData.last_name ? strData && strData.last_name : "";
        if (
          (strData && strData.last_name === null) ||
          (strData && strData.last_name === "")
        ) {
          let checkArr =
            strData.name && strData.name.split(" ").length > 1
              ? strData.name.split(" ")
              : null;
          if (checkArr) {
            tmpLastName = checkArr[checkArr.length - 1];
            let lastIndex = tmpFirstName.lastIndexOf(" ");
            tmpFirstName = tmpFirstName.substring(0, lastIndex);
          }
        }

        if (isCustomer) {
          setFieldValue(
            "title",
            strData && strData.prefix !== ""
              ? replace(strData.prefix, ".", "")
              : "Mr",
          );
          setFieldValue("name", tmpFirstName);
          setFieldValue("last_name", tmpLastName);
          setFieldValue("phonePrefix", tmpPhonePrefix);
          setFieldValue("phone", tmpPhone);
          setFieldValue(
            "email",
            strData && strData.arranger_emails
              ? GetArrangerEmail(strData.arranger_emails)
              : "",
          );
        }
        if (isB2B) {
          setFieldValue(
            "title",
            strData && strData.prefix !== ""
              ? replace(strData.prefix, ".", "")
              : "Mr",
          );
          setFieldValue("name", tmpFirstName);
          setFieldValue("last_name", tmpLastName);
          setFieldValue("phonePrefix", tmpPhonePrefix);
          setFieldValue("phone", tmpPhone);
          setFieldValue(
            "email",
            strData && strData.arranger_emails
              ? GetArrangerEmail(strData.arranger_emails)
              : "",
          );
        }
      }

      setFlag(true);
    }
  }, [flag, isB2B]);

  useEffect(() => {
    if (values?.phonePrefix === "95" && !isB2B) {
      setFieldValue("isCheckSMS", true);
      setFieldValue("isCheckEmail", false);
    } else {
      setFieldValue("isCheckSMS", false);
      setFieldValue("isCheckEmail", true);
    }
  }, [isB2B, setFieldValue, values?.phonePrefix]);

  return (
    <>
      <div
        className={`${classes.insuranceFirstName} ${
          !isEmployee && isAuthenticated() && "dimContactInfo"
        } insuranceFirstName`}
      >
        <Grid item xs={12} className={classes.nameDiv}>
          <Grid item xs={3}>
            <span className={"gender"}>
              {t("travelServices.travel_services_prefix") !==
              "travelServices.travel_services_prefix"
                ? t("travelServices.travel_services_prefix")
                : "Title"}
            </span>
            <select
              name="title"
              onChange={(e) => setFieldValue("title", e.target.value)}
              required
              defaultValue="Mr"
              className="noerror-input"
              id="cars"
            >
              <option defaultValue={true} value="Mr">
                Mr.
              </option>
              <option value="Mrs">Mrs.</option>
              <option value="Ms">Ms.</option>
            </select>
          </Grid>
          <Grid item xs={9}>
            <TextField
              name="name"
              placeholder={
                t("travelServices.travel_services_customer_name") !==
                "travelServices.travel_services_customer_name"
                  ? t("travelServices.travel_services_customer_name")
                  : "First Name + Middle Name *"
              }
              className={
                errors.name && touched.name ? "error-input" : "noerror-input"
              }
              value={values.name || ""}
              onChange={(e) => setFieldValue("name", e.target.value)}
              required
              inputProps={{
                "data-lpignore": "true",
              }}
              variant="standard"
            />
          </Grid>
        </Grid>
      </div>
      <div
        className={`${classes.insuranceLastName} ${
          !isEmployee && isAuthenticated() && "dimContactInfo"
        } insuranceLastName`}
      >
        <Grid item xs={12}>
          <TextField
            name="last_name"
            placeholder={
              t("travelServices.travel_services_customer_last_name") !==
              "travelServices.travel_services_customer_last_name"
                ? t("travelServices.travel_services_customer_last_name")
                : "Last Name *"
            }
            className={
              errors.last_name && touched.last_name
                ? "error-input"
                : "noerror-input"
            }
            value={values.last_name || ""}
            onChange={(e) => setFieldValue("last_name", e.target.value)}
            required
            inputProps={{
              "data-lpignore": "true",
            }}
            variant="standard"
            inputRef={lastNameRef}
            onKeyDown={(event) => {
              if (event.key === "Tab") {
                event.preventDefault();
                if (lastNameRef.current) {
                  phoneNumberRef.current.focus();
                }
              }
            }}
          />
        </Grid>
      </div>
      <div
        className={`${classes.insurancePhone} ${
          !isEmployee && isAuthenticated() && "dimContactInfo"
        } insurancePhone`}
      >
        <Grid
          item
          xs={12}
          className={classes.phoneDiv}
          style={{ flexWrap: "wrap" }}
        >
          <Grid item xs={4} className="travel-services-phoneprefix">
            <PhonePrefix
              name="phonePrefix"
              value={values.phonePrefix || ""}
              prefixValue={values.phonePrefix}
              handlePhonePrefix={(value) => setFieldValue("phonePrefix", value)}
              handlePhoneFlag={(value) =>
                setFieldValue("phoneCountryFlag", value)
              }
              phoneCountryFlag={values.phoneCountryFlag}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              inputRef={phoneNumberRef}
              name="phone"
              placeholder={
                t("travelServices.travel_services_customer_phone") !==
                "travelServices.travel_services_customer_phone"
                  ? t("travelServices.travel_services_customer_phone")
                  : "Phone *"
              }
              className={
                errors.phone && touched.phone ? "error-input" : "noerror-input"
              }
              value={values.phone || ""}
              onChange={(e) => setFieldValue("phone", e.target.value)}
              required
              inputProps={{
                "data-lpignore": "true",
              }}
              variant="standard"
              type="number"
            />
          </Grid>
          <span
            style={{
              color: "red",
              fontSize: "9px",
              paddingLeft: "6px",
              paddingTop: "3px",
            }}
          >
            {((errors.phone && touched.phone) ||
              !PhoneValidation(values.phone)) &&
            values.phone !== ""
              ? t("travelServices.fill_correct_format") !==
                "travelServices.fill_correct_format"
                ? t("travelServices.fill_correct_format")
                : "Please fill correct format e.g.(95) 978 888 77"
              : ""}
          </span>
        </Grid>
      </div>
      <div
        className={`${classes.insuranceEmail} ${
          !isEmployee && isAuthenticated() && "dimContactInfo"
        } insuranceEmail`}
      >
        <Grid item xs={12}>
          <TextField
            name="email"
            placeholder={
              t("travelServices.travel_services_customer_email") !==
              "travelServices.travel_services_customer_email"
                ? t("travelServices.travel_services_customer_email")
                : "Email *"
            }
            className={
              errors.email && touched.email ? "error-input" : "noerror-input"
            }
            value={values.email || ""}
            onChange={(e) => setFieldValue("email", e.target.value)}
            required
            inputProps={{
              "data-lpignore": "true",
            }}
            variant="standard"
            type="email"
          />
        </Grid>
      </div>
      <div className={`${classes.insuranceContactType} insuranceContactType`}>
        <span style={{ fontSize: 12 }}>Contact me via:</span>
        <div>
          <Checkbox
            icon={
              <img
                src={GrayCircleIcon}
                alt="checked"
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            }
            checkedIcon={
              <img
                src={GreenCircleIcon}
                alt="unchecked"
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            }
            checked={values.isCheckSMS}
            name="isSignUp"
            onChange={(e) => {
              console.log("e sms", e);
              if (values.phonePrefix === "95") {
                setFieldValue("isCheckSMS", e.target.checked);
                if (!values?.isCheckEmail) {
                  setFieldValue("isCheckEmail", true);
                }
              } else {
                setFieldValue("isCheckSMS", e.target.checked);
              }
            }}
            value={values.isCheckSMS}
            className="isSmsChecked"
            disabled={values.phonePrefix !== "95" ? true : false}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          />
          <span style={{ fontSize: 12 }}>
            {t(`travelServices.contact_type_phone`) !==
            "travelServices.contact_type_phone"
              ? t(`travelServices.contact_type_phone`)
              : "Phone"}
            {/* {t(`auth.sms`) !== "auth.sms" ? t(`auth.sms`) : "SMS"} */}
          </span>
          <Checkbox
            icon={
              <img
                src={GrayCircleIcon}
                alt="checked"
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            }
            checkedIcon={
              <img
                src={GreenCircleIcon}
                alt="unchecked"
                style={{
                  width: 15,
                  height: 15,
                }}
              />
            }
            checked={values.isCheckEmail}
            name="isSignUp"
            onChange={(e) => {
              console.log("e email", e);
              if (values.phonePrefix === "95") {
                setFieldValue("isCheckEmail", e.target.checked);
                if (!values?.isCheckSMS) {
                  setFieldValue("isCheckSMS", true);
                }
              } else {
                setFieldValue("isCheckEmail", e.target.checked);
              }
            }}
            value={values.isCheckEmail}
            className="isEmailChecked"
            disabled={values.phonePrefix !== "95" ? true : false}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          />
          <span style={{ fontSize: 12 }}>
            {t(`b2c.email`, { ns: "b2c" }) !== "b2c.email"
              ? t(`b2c.email`, { ns: "b2c" })
              : "Email"}
          </span>
        </div>
      </div>
      <Tooltip
        arrow
        title={
          values?.name === "" ||
          values?.last_name === "" ||
          (values?.email === "" && values?.isCheckEmail) ||
          (values?.phone === "" && values?.isCheckSMS)
            ? // || values?.phone === ""
              "Please fill the required information"
            : ""
        }
        // style={{ zIndex: "5000" }}
      >
        <span className={classes.insuranceTooltipSpan}>
          <Button
            color="info"
            variant="contained"
            // onClick={() => handleSubmit()}
            onClick={() => {
              handleSubmit();
            }}
            disabled={
              continueLoading ||
              isSubmitting ||
              (values?.name === "" || values?.last_name === "" || errors.phone
                ? true
                : false)
            }
            className={`${classes.insuranceSendInquiry} btnSearch`}
          >
            {continueLoading ? (
              <CircularProgress
                style={{ color: "#fff", width: 35, height: 35 }}
              />
            ) : t("masterPassengerInformation.continue", {
                ns: "masterPassengerInformation",
              }) !== "masterPassengerInformation.continue" ? (
              t("masterPassengerInformation.continue", {
                ns: "masterPassengerInformation",
              })
            ) : (
              "Continue"
            )}
            {/* {t("travelServices.send_inquiry") !== "travelServices.send_inquiry"
              ? t("travelServices.send_inquiry")
              : "Send Inquiry"} */}
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      return {
        title: "Mr",
        name: "",
        last_name: "",
        phoneCountryFlag: "mm",
        phonePrefix: "95",
        phone: "",
        email: "",
        isCheckEmail: false,
        isCheckSMS: false,
      };
    },

    validationSchema: (props) => InsuranceValidationSchema(props),

    handleSubmit: (values, { props, resetForm }) => {
      props.setcontinueLoading(true);
      props.handleReservation(values, resetForm);
    },

    displayName: "BasicForm",
  }),
)(InsurancePassengerFormWeb);
