import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SelectBoxIcon from "@material-ui/icons/ExpandMore";

const HotelSortDropdown = ({ sortByValue, handleSortBy, from }) => {
  const { t } = useTranslation(["2Search", "hotel"]);

  return (
    <div
      className="d-flex align-items-center justify-content-between"
      id="hotelSortBy"
      style={{ gap: "5px" }}
    >
      {from && from === "hotel" && (
        <span>
          {t("2Search.sort_by") !== "2Search.sort_by"
            ? t("2Search.sort_by")
            : "Sort By"}{" "}
          :{" "}
        </span>
      )}
      <Select
        inputProps={{
          name: "hotelSortBy",
        }}
        value={sortByValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        IconComponent={SelectBoxIcon}
        onChange={handleSortBy}
        autoWidth={true}
      >
        <MenuItem value="default">
          {t("hotel.default", { ns: "hotel" }) !== "hotel.default"
            ? t("hotel.default", { ns: "hotel" })
            : "Default"}
        </MenuItem>
        <MenuItem value="lowest_price">
          {t("hotel.lowest_price", { ns: "hotel" }) !== "hotel.lowest_price"
            ? t("hotel.lowest_price", { ns: "hotel" })
            : "Lowest Price"}
        </MenuItem>
        <MenuItem value="highest_price">
          {t("hotel.highest_price", { ns: "hotel" }) !== "hotel.highest_price"
            ? t("hotel.highest_price", { ns: "hotel" })
            : "Highest Price"}
        </MenuItem>
      </Select>
    </div>
  );
};

export default HotelSortDropdown;
