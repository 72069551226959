import React, { useEffect, useRef, useState } from "react";
import { checkInApp, getClientTag, getLocale } from "../../utils/checkurl";
import "../../assets/scss/loginusers/Profile.scss";
import "../../assets/scss/loginusers/Passenger.scss";

import { Box, Button, Card, CardContent, Grid } from "@material-ui/core";
import DeleteAccountDialog from "./DeleteAccountDialog";
import LoginSignupModal from "../login/LoginSignupModal";
import PageHeader from "../../pages/PageHeader";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import "../../assets/scss/loginusers/BookingHistory.scss";

import { getProfileId, getUserProfileInfo } from "../../utils/loginutils";
import clientV3 from "../../apolloClient";
import { GET_ADDRESSBOOK } from "../../queries/addressbookquery";
import {
  ADDRESSBOOK_ID,
  EN_LOCALE,
  LOGIN_USER_ID,
  MM_LOCALE,
  MY_MM,
} from "../../config/constants";
import { ArrowBackIos } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import useFlymyaSessionHook from "../../hooks/FlymyaSessionHook";
import HomeMenu from "../HomeMenu";

const AddressBookDetail = (props) => {
  const params = useParams();
  const [myanmarFont] = useState(
    getLocale(params.locale) === MY_MM ? "myanmarFont" : "",
  );
  const [showMenu, setShowMenu] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("flight");
  const navigate = useNavigate();
  const [selectedLanguage] = useState(getLocale(params.locale));
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDim, setIsDim] = useState(false);
  const { t } = useTranslation(["b2c"]);

  const [language] = useState(
    getLocale(params.locale) === MY_MM ? MM_LOCALE : EN_LOCALE,
  );

  const { handleFlymyaSession } = useFlymyaSessionHook();

  useEffect(() => {
    handleFlymyaSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditPassenger = () => {
    setIsShow(true);
  };

  const handleDeletePassenger = () => {
    setOpen(true);
  };

  const handleGoBack = () => {
    window.location.href = `/${selectedLanguage}/address-book/${localStorage.getItem(
      LOGIN_USER_ID,
    )}`;
  };

  const getAddressBook = () => {
    let addId = localStorage.getItem(ADDRESSBOOK_ID);
    clientV3()
      .query({
        query: GET_ADDRESSBOOK,
        fetchPolicy: "network-only",
        variables: {
          id: addId,
        },
      })
      .then((res) => {
        res && res?.data?.addressBook && setData(res?.data?.addressBook);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAddressBook();
  }, []);

  return (
    <div className="profileContainer">
      {/* <PageHeader
        pageName={"ProfilePage"}
        selectedLanguage={selectedLanguage}
        pathName={props.location}
        isInApp={isInApp}
      /> */}
      <HomeMenu
        props={props}
        isInApp={isInApp}
        isInWeb={window.location.pathname.includes("in-web") ? true : false}
        myanmarFont={myanmarFont}
        selectedLanguage={selectedLanguage}
        clientTag={getClientTag(window.location.search)}
        toggleDrawer={() => setShowMenu(!showMenu)}
        showMenu={showMenu}
        pageName="AboutUs"
        // gorgiasInfo={gorgiasInfo}
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
      />
      <Box mt={5}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Card className="card">
              <CardContent>
                <div className="cardContainer">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <span className="userProfile">AddressBook</span> */}
                    <Button
                      style={{
                        marginRight: "10px",
                        color: "#00aeef",
                        cursor: "pointer",
                        textTransform: "capitalize",
                      }}
                      className="userProfile"
                      onClick={handleGoBack}
                      startIcon={<ArrowBackIos />}
                    >
                      {t(`b2c.back`) !== "b2c.back" ? t(`b2c.back`) : "Back"}
                    </Button>
                    {/* <span
                      onClick={handleGoBack}
                      style={{
                        marginRight: "10px",
                        color: "#00aeef",
                        cursor: "pointer",
                      }}
                      className="userProfile"
                    >
                      Back
                    </span> */}
                  </div>

                  <div className="profile">
                    <span className="profileName">
                      {data?.prefix} {data?.first_name} {data?.last_name}
                    </span>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <span
                      style={{
                        color: "#000",
                        fontSize: "12px",
                        marginBottom: "10px",
                        backgroundColor: "#e5f7fd",
                        borderRadius: "20px",
                        padding: "4px",
                      }}
                    >
                      {t(`b2c.passenger`) !== "b2c.passenger"
                        ? t(`b2c.passenger`)
                        : "Passenger"}
                    </span>
                  </div>
                  <div className="cardTextWrapper">
                    <div className="leftCardText">
                      {t(`b2c.nationality`) !== "b2c.nationality"
                        ? t(`b2c.nationality`)
                        : "Nationality"}
                    </div>
                    <div className="rightCardText">
                      {data?.nationality ? data?.nationality?.name : "-"}
                    </div>
                  </div>
                  <div className="cardTextWrapper">
                    <div className="leftCardText">
                      {t(`b2c.dob`) !== "b2c.dob" ? t(`b2c.dob`) : "DOB"}
                    </div>
                    <div className="rightCardText">
                      {data?.dob
                        ? moment(data?.dob, "YYYY-MM-DD").format("DD/MM/YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div className="cardTextWrapper">
                    <div className="leftCardText">
                      {t(`b2c.nrc`) !== "b2c.nrc" ? t(`b2c.nrc`) : "NRC"}
                    </div>
                    <div className="rightCardText">
                      {data?.nationality &&
                      data?.nationality?.name === "Myanmar"
                        ? data?.nrc
                          ? data?.nrc
                          : "-"
                        : "-"}
                    </div>
                  </div>
                  <div className="cardTextWrapper">
                    <div className="leftCardText">
                      {t(`b2c.passport`) !== "b2c.passport"
                        ? t(`b2c.passport`)
                        : "Passport"}
                    </div>
                    <div className="rightCardText">
                      {data?.passport ? data?.passport : "-"}
                    </div>
                  </div>
                  <div className="cardTextWrapper">
                    <div className="leftCardText">Expiry Date</div>
                    <div className="rightCardText">
                      {data?.passport_exp ? data?.passport_exp : "-"}
                    </div>
                  </div>
                  {data?.nationality?.name === "Myanmar" ? (
                    <>
                      <div className="cardTextWrapper">
                        <div className="leftCardText">
                          {t(`b2c.region`) !== "b2c.region"
                            ? t(`b2c.region`)
                            : "Region"}
                        </div>
                        <div className="rightCardText">
                          {data?.township?.city?.region?.name
                            ? data?.township?.city?.region?.name
                            : "-"}
                        </div>
                      </div>
                      <div className="cardTextWrapper">
                        <div className="leftCardText">Township</div>
                        <div className="rightCardText">
                          {data?.township?.name ? data?.township?.name : "-"}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="cardTextWrapper">
                    <div className="leftCardText">Address</div>
                    <div className="rightCardText">
                      {data?.address ? data?.address : "-"}
                    </div>
                  </div>

                  <div style={{ marginTop: "25px" }}>
                    <Button
                      onClick={handleEditPassenger}
                      variant="contained"
                      // style={{
                      //   textTransform: "capitalize",
                      //   color: "#fff",
                      //   fontSize: "18px",
                      //   backgroundColor: "#00aeef",
                      //   width: "88%",
                      // }}
                      className="editPassenger"
                    >
                      {t(`b2c.edit_passenger`) !== "b2c.edit_passenger"
                        ? t(`b2c.edit_passenger`)
                        : "Edit Passenger"}
                    </Button>
                  </div>

                  <div style={{ marginTop: "18px" }}>
                    <Button
                      onClick={handleDeletePassenger}
                      variant="outlined"
                      // style={{
                      //   textTransform: "capitalize",
                      //   color: "#EB7774",
                      //   fontSize: "18px",
                      //   border: "1px solid #eb7774",
                      //   width: "88%",
                      // }}
                      className="removePassenger"
                    >
                      {t(`b2c.remove`) !== "b2c.remove"
                        ? t(`b2c.remove`)
                        : "Remove"}
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <DeleteAccountDialog page="addressBook" open={open} setOpen={setOpen} />
      <LoginSignupModal
        isInApp={isInApp}
        addId="addressBookEdit"
        data={data}
        isShow={isShow}
        setIsShow={() => setIsShow(false)}
        setIsSpinner={setIsSpinner}
        isDim={isDim}
        setIsDim={setIsDim}
        loginEmail={loginEmail}
        setLoginEmail={setLoginEmail}
        loginPassword={loginPassword}
        setLoginPassword={setLoginPassword}
        // handleLogin={handleLogin}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        isForget={isForget}
        setIsForget={setIsForget}
        isSpinner={isSpinner}
        // setIsCheck={setIsCheck}
        selectedLanguage={selectedLanguage}
        // clientTag={clientTag}
        page="addressBook"
      />
    </div>
  );
};

export default AddressBookDetail;
