import React from "react";
import Grid from "@material-ui/core/Grid";
import { MY_MM } from "../../config/constants";
import { useTranslation } from "react-i18next";

const BusProgressbar = ({ pageName, selectedLanguage }) => {
  const { t } = useTranslation([
    "masterPassengerInformation",
    "2Search",
    "selectSeat",
  ]);

  return (
    <Grid
      item
      xs={10}
      sm={10}
      md={7}
      className="progressBarContainer busProgressbar"
    >
      <ul
        className={
          selectedLanguage === MY_MM
            ? "progressbar mm-progress"
            : "progressbar eng-progress"
        }
      >
        <li
          className={
            pageName === "BusSearch"
              ? "active"
              : "" + pageName !== "/"
              ? "completed"
              : ""
          }
        >
          <span className="step-info">
            {t("2Search.search", { ns: "2Search" }) !== "2Search.search"
              ? t("2Search.search", { ns: "2Search" })
              : "Search"}
          </span>
        </li>
        <li
          className={
            pageName === "BusSeat"
              ? "active"
              : "" + pageName === "BusContactInfo" ||
                pageName === "BusPayment" ||
                pageName === "BusConfirmation" ||
                pageName === "BusTransactionfail"
              ? "completed"
              : ""
          }
        >
          <span className="step-info">
            {t(`selectSeat.select_seat`, { ns: "selectSeat" }) !==
            "selectSeat.select_seat"
              ? t(`selectSeat.select_seat`, { ns: "selectSeat" })
              : "Select"}
          </span>
        </li>
        <li
          className={
            pageName === "BusContactInfo"
              ? "active"
              : "" + pageName === "BusPayment" ||
                pageName === "BusConfirmation" ||
                pageName === "BusTransactionfail"
              ? "completed"
              : ""
          }
        >
          <span className="step-info">
            {t(`2Search.info`, { ns: "2Search" }) !== "2Search.info"
              ? t(`2Search.info`, { ns: "2Search" })
              : "Info"}
          </span>
        </li>
        <li
          className={
            pageName === "BusPayment"
              ? "active"
              : "" + pageName === "BusConfirmation" ||
                pageName === "BusTransactionfail"
              ? "completed"
              : ""
          }
        >
          <span className="step-info">
            {t("masterPassengerInformation.pay") !==
            "masterPassengerInformation.pay"
              ? t("masterPassengerInformation.pay")
              : "Pay"}
          </span>
        </li>
        <li
          className={
            pageName === "BusConfirmation"
              ? "active"
              : "" + pageName === "BusTransactionfail"
              ? "warning"
              : ""
          }
        >
          <span className="step-info">
            {t("masterPassengerInformation.confirm") !==
            "masterPassengerInformation.confirm"
              ? t("masterPassengerInformation.confirm")
              : "Confirm"}
          </span>
        </li>
      </ul>
    </Grid>
  );
};
export default BusProgressbar;
