export const CustomSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #cbcbcb",
    backgroundColor: "white",
    padding: "4px 2px;",
    fontSize: "12px",
    minHeight: "unset",
    outline: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#00aeef"
      : state.isFocused
      ? "#f0f0f0"
      : null,
    color: state.isSelected ? "white" : "black",
    padding: "5px",
    fontSize: "12px",
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: "100px",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    padding: "5px",
    textAlign: "left",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
    fontSize: "12px",
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 0",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#999",
    fontSize: "12px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    "& indicatorContainer": {
      padding: 0,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    "& svg": {
      width: "15px",
      height: "15px",
      fill: "#333",
    },
    "&:hover svg": {
      fill: "#333",
    },
  }),
};
