import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../assets/scss/loginusers/Passenger.scss";
import EditIcon from "../../assets/images/edit-icon-white.svg";
import {
  ADDRESSBOOK_ID,
  EN_LOCALE,
  LOGIN_USER_ID,
  MM_LOCALE,
  MY_MM,
} from "../../config/constants";
import {
  checkInApp,
  checkInWeb,
  getClientTag,
  getLocale,
} from "../../utils/checkurl";
import { getAge, getPassengerType } from "../../utils/addressbookUtils";
import { useTranslation } from "react-i18next";
import LoginSignupModal from "../../pages/login/LoginSignupModal";
import moment from "moment";

const Passenger = ({
  addBook,
  getInAppBgColor,
  setOpenDelete,
  isForget,
  setIsForget,
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  isSpinner,
  setIsSpinner,
  errorMsg,
  setErrorMsg,
  isDim,
  setIsDim,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [passType, setPassType] = useState("");
  const [language] = useState(
    getLocale(params.locale) === MY_MM ? MM_LOCALE : EN_LOCALE,
  );
  const [selectedLanguage] = useState(getLocale(params.locale));
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const { t } = useTranslation(["masterHomePage", "b2c"]);
  const [clientTag] = useState(getClientTag(window.location.search));
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    let age = getAge(addBook?.dob);
    let passengerType = getPassengerType(age);
    let psType;
    if (passengerType === "Adult") {
      psType =
        t("masterHomePage.adult") !== "masterHomePage.adult"
          ? t("masterHomePage.adult")
          : "Adult";
    } else if (passengerType === "Child") {
      psType =
        t("masterHomePage.child") !== "masterHomePage.child"
          ? t("masterHomePage.child")
          : "Child";
    } else {
      psType =
        t("masterHomePage.infant") !== "masterHomePage.infant"
          ? t("masterHomePage.infant")
          : "Infant";
    }
    setPassType(psType);
  }, [addBook?.dob]);

  return (
    <div>
      <div className="passengerContainer">
        <div className="passengerAddressbook">
          <div className="fullNameText">
            {addBook && addBook?.prefix} {addBook?.first_name}{" "}
            {addBook?.last_name}
          </div>
          <div className="arranger">{passType}</div>
          <div className="borderStyle" />
          <div className="cardTextWrapper">
            <div className="leftCardText">
              {t(`b2c.nationality`, { ns: "b2c" }) !== "b2c.nationality"
                ? t(`b2c.nationality`, { ns: "b2c" })
                : "Nationality"}
            </div>
            <div className="fullNameText">
              {addBook?.nationality ? addBook?.nationality?.name : "-"}
            </div>
          </div>

          <div className="cardTextWrapper">
            <div className="leftCardText">
              {t(`b2c.dob`, { ns: "b2c" }) !== "b2c.dob"
                ? t(`b2c.dob`, { ns: "b2c" })
                : "DOB"}
            </div>
            <div className="fullNameText">
              {addBook?.dob
                ? moment(addBook?.dob, "YYYY-MM-DD").format("ddd, DD MMM YYYY")
                : "-"}
              {` (Age ${moment().diff(addBook?.dob, "years")})`}
            </div>
          </div>

          <div className="cardTextWrapper">
            <div className="leftCardText">
              {t(`b2c.nrc`, { ns: "b2c" }) !== "b2c.nrc"
                ? t(`b2c.nrc`, { ns: "b2c" })
                : "NRC"}
            </div>
            <div className="fullNameText">
              {addBook?.nationality && addBook?.nationality?.name === "Myanmar"
                ? addBook?.nrc
                  ? addBook?.nrc
                  : "-"
                : "-"}
            </div>
          </div>

          <div className="cardTextWrapper">
            <div className="leftCardText">
              {t(`b2c.passport`, { ns: "b2c" }) !== "b2c.passport"
                ? t(`b2c.passport`, { ns: "b2c" })
                : "Passport"}
            </div>
            <div className="fullNameText">
              {addBook?.passport ? addBook?.passport : "-"}
            </div>
          </div>

          <div className="cardTextWrapper">
            <div className="leftCardText">{"Passport Expiry Date"}</div>
            <div className="fullNameText">
              {addBook?.passport_exp
                ? moment(addBook?.passport_exp, "YYYY-MM-DD").format(
                    "ddd, DD MMM YYYY",
                  )
                : "-"}
            </div>
          </div>
          <div className="buttonDiv">
            <div
              className="buttonStyle"
              style={{
                backgroundColor: "#EC516D",
              }}
              onClick={() => setOpenDelete(true)}
            >
              {"Delete"}
            </div>
            <div
              className="buttonStyle"
              style={{
                backgroundColor: getInAppBgColor(clientTag),
              }}
              onClick={() => setIsShow(true)}
            >
              {"Edit"}
            </div>
          </div>
        </div>
      </div>
      <LoginSignupModal
        isInApp={isInApp}
        addId="addressBookEdit"
        data={addBook}
        isShow={isShow}
        setIsShow={() => setIsShow(false)}
        setIsSpinner={setIsSpinner}
        isDim={isDim}
        setIsDim={setIsDim}
        loginEmail={loginEmail}
        setLoginEmail={setLoginEmail}
        loginPassword={loginPassword}
        setLoginPassword={setLoginPassword}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        isForget={isForget}
        setIsForget={setIsForget}
        isSpinner={isSpinner}
        selectedLanguage={selectedLanguage}
        page="addressBook"
      />
    </div>
  );
};

export default Passenger;
