//import { ApolloClient } from "apollo-boost";
import { ApolloClient, InMemoryCache } from "@apollo/client";
//import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";
import { AUTH_TOKEN, TOKEN_TYPE } from "./config/constants";
import { isAuthenticated } from "./lib/auth";
import { IS_CUSTOMER_VAL } from "./config/typecheck";

const clientV3Upload = (endpoint) => {
  const authLink = setContext((_, { headers }) => {
    const tokenType = localStorage.getItem(TOKEN_TYPE);
    const token = localStorage.getItem(AUTH_TOKEN);
    return {
      headers: {
        ...headers,
        authorization: token ? `${tokenType} ${token}` : "",
      },
    };
  });

  const httpLinkV3Upload = new createUploadLink({
    uri:
      process.env.REACT_APP_BE_SERVER +
      `${
        endpoint
          ? endpoint
          : !isAuthenticated() //for guest
          ? process.env.REACT_APP_GQL_GUEST
          : isAuthenticated() && IS_CUSTOMER_VAL //for customer
          ? process.env.REACT_APP_GQL_CUSTOMER
          : process.env.REACT_APP_GQL_DEFAULT // for b2b and others
      }`,
  });

  return new ApolloClient({
    link: authLink.concat(httpLinkV3Upload),
    cache: new InMemoryCache(),
  });
};

export default clientV3Upload;
