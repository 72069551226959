import React from "react";
import { useTranslation } from "react-i18next";

const MonthOptionComponent = () => {
  const { t } = useTranslation(["masterPassengerInformation"]);

  return (
    <>
      <option value="00" disabled>
        {t("masterPassengerInformation.month") !==
        "masterPassengerInformation.month"
          ? t("masterPassengerInformation.month")
          : "Month"}
      </option>
      <option value="01">January</option>
      <option value="02">February</option>
      <option value="03">March</option>
      <option value="04">April</option>
      <option value="05">May</option>
      <option value="06">June</option>
      <option value="07">July</option>
      <option value="08">August</option>
      <option value="09">September</option>
      <option value="10">October</option>
      <option value="11">November</option>
      <option value="12">December</option>
    </>
  );
};

export default MonthOptionComponent;
