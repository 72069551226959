import React from "react";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress } from "@mui/material";
import { CALL_CENTER_PHONENO } from "../../../config/constants";
import { useTheme } from "@mui/material/styles";
import { KBZ } from "../../../config/inappclients";

const CustomerEmailPassForm = ({
  isDim,
  errorMsg,
  setErrorMsg,
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  setIsCheck,
  handleLogin,
  isSpinner,
  isForget,
  setIsForget,
  handleResetPassword,
  clientTag,
}) => {
  const { t } = useTranslation([
    "b2c",
    "auth",
    "masterPassengerInformationTwo",
  ]);
  const theme = useTheme();

  return (
    <div className={isDim ? "dim-div infobox" : "infobox"}>
      <label>
        {t(`b2c.email`) !== "b2c.email" ? `${t(`b2c.email`)} *` : "Email *"}
      </label>
      <input
        className={errorMsg !== "" ? "error-textbox" : ""}
        name="loginEmail"
        type="text"
        placeholder={t(`b2c.email`) !== "b2c.email" ? t(`b2c.email`) : "Email"}
        value={loginEmail}
        onChange={(e) => {
          setLoginEmail(e.target.value);
        }}
        onBlur={(e) => {
          // if (!validateEmail(e.target.value))
          //   setErrorMsg("Please fill correct Email");
          // else setErrorMsg("");
          setErrorMsg("");
        }}
        autoComplete="off"
        required
        error={errorMsg !== "" ? true : false}
        variant="standard"
      />

      {!isForget && (
        <>
          <label>
            {t("masterPassengerInformationTwo.password", {
              ns: "masterPassengerInformationTwo",
            }) !== "masterPassengerInformationTwo.password"
              ? `${t("masterPassengerInformationTwo.password", {
                  ns: "masterPassengerInformationTwo",
                })} *`
              : "Password *"}
          </label>
          <input
            className={errorMsg !== "" ? "error-textbox" : ""}
            name="loginPassword"
            type="password"
            placeholder={
              t("masterPassengerInformationTwo.password", {
                ns: "masterPassengerInformationTwo",
              }) !== "masterPassengerInformationTwo.password"
                ? t("masterPassengerInformationTwo.password", {
                    ns: "masterPassengerInformationTwo",
                  })
                : "Password"
            }
            value={loginPassword}
            autoComplete="off"
            onChange={(e) => {
              setErrorMsg("");
              setLoginPassword(e.target.value);
            }}
            required
            error={errorMsg !== "" ? true : false}
            variant="standard"
          />
        </>
      )}

      {errorMsg !== "" && (
        <>
          <p>Ph no - {CALL_CENTER_PHONENO} </p>
          <p>Email - booking@flymya.co</p>
        </>
      )}

      {errorMsg !== "" && <p>{errorMsg}</p>}

      {!isForget && (
        <Button
          color="primary"
          variant="contained"
          type="button"
          id="btn-sign-in"
          onClick={() => {
            setIsCheck(false);
            handleLogin();
          }}
          disabled={
            errorMsg !== "" || loginEmail === "airticket770@gmail.com"
              ? true
              : false
          }
        >
          {t("masterPassengerInformationTwo.sign_in", {
            ns: "masterPassengerInformationTwo",
          }) !== "masterPassengerInformationTwo.sign_in"
            ? t("masterPassengerInformationTwo.sign_in", {
                ns: "masterPassengerInformationTwo",
              })
            : "Sign In"}{" "}
          {isSpinner && <CircularProgress />}
        </Button>
      )}

      {isForget && (
        <Button
          color="primary"
          variant="contained"
          type="button"
          id="btn-sign-in"
          onClick={() => handleResetPassword()}
          disabled={
            errorMsg !== "" || loginEmail === "airticket770@gmail.com"
              ? true
              : false
          }
        >
          {t(`auth.sent_instructions`, { ns: "auth" }) !==
          "auth.sent_instructions"
            ? t(`auth.sent_instructions`, { ns: "auth" })
            : "Sent Instructions"}{" "}
          {isSpinner && <CircularProgress />}
        </Button>
      )}

      {!isForget && clientTag !== KBZ && (
        <p
          style={{ color: theme.palette.primary.main }}
          className="forgot-email"
          onClick={() => {
            setErrorMsg("");
            setIsForget(true);
          }}
        >
          {t("masterPassengerInformationTwo.forgot_password", {
            ns: "masterPassengerInformationTwo",
          }) !== "masterPassengerInformationTwo.forgot_password"
            ? t("masterPassengerInformationTwo.forgot_password", {
                ns: "masterPassengerInformationTwo",
              })
            : "Forgot Password?"}
        </p>
      )}
    </div>
  );
};

export default CustomerEmailPassForm;
