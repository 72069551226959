import React, { useEffect, useRef } from "react";
import { Grid, TextField, Button, Checkbox } from "@mui/material";
import { withFormik } from "formik";
import HotelValidationSchema from "./HotelValidationSchema";
import { compose } from "recompose";
import PhonePrefix from "../../components/inputs/PhonePrefix";
import { HotelPassengerFormWebStyles } from "./styles/HotelPassengerFormWebStyles";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../assets/images/info-icon.svg";
import GrayCircleIcon from "../../assets/images/insider/gray_circle.svg";
import GreenCircleIcon from "../../assets/images/insider/green_circle.svg";
import { IS_B2B } from "../../config/constants";

const HotelPassengerFormWeb = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  formikRef,
  handleReservation,
}) => {
  const classes = HotelPassengerFormWebStyles();
  const { t } = useTranslation(["travelServices", "b2c"]);
  const isB2B =
    localStorage.getItem(IS_B2B) && localStorage.getItem(IS_B2B) === "yes"
      ? true
      : false;
  const phoneNumberRef = useRef(null);
  const lastNameRef = useRef(null);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        title: "Mr",
        name: "",
        last_name: "",
        phoneCountryFlag: "mm",
        phonePrefix: "95",
        phone: "",
        email: "",
        isCheckSMS: true,
        isCheckEmail: false,
      }}
      onSubmit={(values, { resetForm }) => {
        handleReservation(values, resetForm);
      }}
      validationSchema={HotelValidationSchema(t)}
    >
      {({
        values,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        setFieldValue,
      }) => {
        useEffect(() => {
          console.log("values", values);
          if (values?.phonePrefix === "95" && !isB2B) {
            setFieldValue("isCheckSMS", true);
            setFieldValue("isCheckEmail", false);
          } else {
            setFieldValue("isCheckSMS", false);
            setFieldValue("isCheckEmail", true);
          }
        }, [isB2B, setFieldValue, values?.phonePrefix]);
        return (
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              width: `95%`,
              justifyContent: "space-between",
            }}
          >
            <>
              <div className={`${classes.insuranceFirstName}`}>
                <Grid item xs={12} className={classes.nameDiv}>
                  <Grid item xs={3}>
                    <span className={"gender"}>
                      {t("travelServices.travel_services_prefix") !==
                      "travelServices.travel_services_prefix"
                        ? t("travelServices.travel_services_prefix")
                        : "Title"}
                    </span>
                    <select
                      name="title"
                      onChange={(e) => setFieldValue("title", e.target.value)}
                      required
                      defaultValue="Mr"
                      className="noerror-input"
                      id="cars"
                    >
                      <option defaultValue={true} value="Mr">
                        Mr.
                      </option>
                      <option value="Mrs">Mrs.</option>
                      <option value="Ms">Ms.</option>
                    </select>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name="name"
                      placeholder={
                        t("travelServices.travel_services_customer_name") !==
                        "travelServices.travel_services_customer_name"
                          ? t("travelServices.travel_services_customer_name")
                          : "First Name + Middle Name *"
                      }
                      className={
                        errors.name && touched.name
                          ? "error-input"
                          : "noerror-input"
                      }
                      value={values.name || ""}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      required
                      inputProps={{
                        "data-lpignore": "true",
                      }}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={`${classes.insuranceLastName}`}>
                <Grid item xs={12}>
                  <TextField
                    name="last_name"
                    placeholder={
                      t("travelServices.travel_services_customer_last_name") !==
                      "travelServices.travel_services_customer_last_name"
                        ? t("travelServices.travel_services_customer_last_name")
                        : "Last Name *"
                    }
                    className={
                      errors.last_name && touched.last_name
                        ? "error-input"
                        : "noerror-input"
                    }
                    value={values.last_name || ""}
                    onChange={(e) => setFieldValue("last_name", e.target.value)}
                    required
                    inputProps={{
                      "data-lpignore": "true",
                    }}
                    variant="standard"
                    inputRef={lastNameRef}
                    onKeyDown={(event) => {
                      if (event.key === "Tab") {
                        event.preventDefault();
                        if (lastNameRef.current) {
                          phoneNumberRef.current.focus();
                        }
                      }
                    }}
                  />
                </Grid>
              </div>
              <div className={classes.insurancePhone}>
                <Grid item xs={12} className={classes.phoneDiv}>
                  <Grid item xs={4} className="travel-services-phoneprefix">
                    <PhonePrefix
                      name="phonePrefix"
                      value={values.phonePrefix || ""}
                      prefixValue={values.phonePrefix}
                      handlePhonePrefix={(value) =>
                        setFieldValue("phonePrefix", value)
                      }
                      handlePhoneFlag={(value) =>
                        setFieldValue("phoneCountryFlag", value)
                      }
                      phoneCountryFlag={values.phoneCountryFlag}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      inputRef={phoneNumberRef}
                      name="phone"
                      type="number"
                      placeholder={
                        t("travelServices.travel_services_customer_phone") !==
                        "travelServices.travel_services_customer_phone"
                          ? t("travelServices.travel_services_customer_phone")
                          : "Phone *"
                      }
                      className={
                        values?.phone !== "" && (errors.phone || touched.phone)
                          ? "error-input"
                          : "noerror-input"
                      }
                      value={values.phone || ""}
                      onChange={(e) => setFieldValue("phone", e.target.value)}
                      required
                      inputProps={{
                        "data-lpignore": "true",
                      }}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
                {values?.phone !== "" &&
                  errors.phone &&
                  errors.phone === "Please enter valid number." && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "9px",
                        paddingLeft: "5px",
                        paddingTop: "3px",
                        width: "100%",
                      }}
                    >
                      {t("travelServices.fill_correct_format") !==
                      "travelServices.fill_correct_format"
                        ? t("travelServices.fill_correct_format")
                        : "Please fill correct format e.g.(95) 978 888 77"}
                    </span>
                  )}
              </div>
              <div className={`${classes.insuranceEmail}`}>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    placeholder={
                      t("travelServices.travel_services_customer_email") !==
                      "travelServices.travel_services_customer_email"
                        ? t("travelServices.travel_services_customer_email")
                        : "Email *"
                    }
                    className={
                      errors.email || touched.email
                        ? "error-input"
                        : "noerror-input"
                    }
                    value={values.email || ""}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    required
                    inputProps={{
                      "data-lpignore": "true",
                    }}
                    variant="standard"
                    type="email"
                  />
                </Grid>
                {errors.email &&
                  errors.email === "Please enter valid email address." && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "9px",
                        paddingLeft: "5px",
                        paddingTop: "3px",
                        width: "100%",
                      }}
                    >
                      Please fill correct email
                    </span>
                  )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <span style={{ fontSize: 12 }}>
                  {/* {t(`auth.send_confirm_booking`) !== "auth.send_confirm_booking"
              ? t(`auth.send_confirm_booking`)
              : "Send my confirmation booking via:"} */}
                  Contact me via:
                </span>
                <div>
                  <Checkbox
                    icon={
                      <img
                        src={GrayCircleIcon}
                        alt="checked"
                        style={{ width: 15, height: 15 }}
                      />
                    }
                    checkedIcon={
                      <img
                        src={GreenCircleIcon}
                        alt="unchecked"
                        style={{ width: 15, height: 15 }}
                      />
                    }
                    checked={values.isCheckSMS}
                    name="isSignUp"
                    onChange={(e) => {
                      console.log("e sms", e);
                      if (values.phonePrefix === "95") {
                        setFieldValue("isCheckSMS", e.target.checked);
                        if (!values?.isCheckEmail) {
                          setFieldValue("isCheckEmail", true);
                        }
                      } else {
                        setFieldValue("isCheckSMS", e.target.checked);
                      }
                    }}
                    value={values.isCheckSMS}
                    className="isSmsChecked"
                    disabled={values.phonePrefix !== "95" ? true : false}
                    style={{ paddingTop: 3, paddingBottom: 3 }}
                  />
                  <span style={{ fontSize: 12 }}>
                    {t(`travelServices.contact_type_phone`) !==
                    "travelServices.contact_type_phone"
                      ? t(`travelServices.contact_type_phone`)
                      : "Phone"}
                    {/* {t(`auth.sms`) !== "auth.sms" ? t(`auth.sms`) : "SMS"} */}
                  </span>
                  <Checkbox
                    icon={
                      <img
                        src={GrayCircleIcon}
                        alt="checked"
                        style={{ width: 15, height: 15 }}
                      />
                    }
                    checkedIcon={
                      <img
                        src={GreenCircleIcon}
                        alt="unchecked"
                        style={{ width: 15, height: 15 }}
                      />
                    }
                    checked={values.isCheckEmail}
                    name="isSignUp"
                    onChange={(e) => {
                      console.log("e email", e);
                      if (values.phonePrefix === "95") {
                        setFieldValue("isCheckEmail", e.target.checked);
                        if (!values?.isCheckSMS) {
                          setFieldValue("isCheckSMS", true);
                        }
                      } else {
                        setFieldValue("isCheckEmail", e.target.checked);
                      }
                    }}
                    value={values.isCheckEmail}
                    className="isEmailChecked"
                    disabled={values.phonePrefix !== "95" ? true : false}
                    style={{ paddingTop: 3, paddingBottom: 3 }}
                  />
                  <span style={{ fontSize: 12 }}>
                    {t(`b2c.email`, { ns: "b2c" }) !== "b2c.email"
                      ? t(`b2c.email`, { ns: "b2c" })
                      : "Email"}
                  </span>
                </div>
              </div>
              {/* <Button
        color="info"
        variant="contained"
        onClick={() => handleSubmit()}
        disabled={isSubmitting}
        className={`${classes.insuranceSendInquiry} btnSearch`}
      >
        {t("travelServices.send_inquiry") !== "travelServices.send_inquiry"
          ? t("travelServices.send_inquiry")
          : "Send Inquiry"}
      </Button> */}
            </>
          </form>
        );
      }}
    </Formik>
  );
};

export default HotelPassengerFormWeb;
