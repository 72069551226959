import React, { useState } from "react";
//import ReactFlagsSelect from "react-flags-select";
//import "react-flags-select/scss/react-flags-select.scss";
import Popper from "@material-ui/core/Popper";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { MY_MM } from "../../config/constants";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import MediaQuery from "react-responsive";
import EngMobileFlag from "../../assets/images/flags/en-flag-mobile.webp";
import MMMobileFlag from "../../assets/images/flags/mm-flag-mobile.webp";
import ChineseFlag from "../../assets/images/flags/china.png";
import ThaiFlag from "../../assets/images/flags/thailand.png";
import { useLocation } from "react-router-dom";
import "../../assets/scss/LngPopper.scss";
import { useTranslation } from "react-i18next";
import { GET_LANGUAGES } from "../../queries/loginquery";
import { useQuery } from "@apollo/react-hooks";
import { ConstructionOutlined } from "@mui/icons-material";

const lngArr = [
  { value: "en", label: "English (EN)" },
  { value: "my", label: "မြန်မာ (MM)" },
  { value: "zh", label: "中文 (ZH)" }, // Chinese
  { value: "th", label: "ไทย (TH)" }, // Thai
];

const flags = {
  en: EngMobileFlag,
  my: MMMobileFlag,
  zh: ChineseFlag,
  th: ThaiFlag,
};

const ReactFlags = (props) => {
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();

  const mapLanguageName = (name) => {
    switch (name.toLowerCase()) {
      case "burmese":
        return "မြန်မာ";
      case "thailand":
        return "ไทย";
      case "chinese":
        return "中文";
      // Add more cases for other languages as needed
      default:
        return name;
    }
  };

  const { loading, error, data, refetch } = useQuery(GET_LANGUAGES, {
    fetchPolicy: "network-only",
    variables: {
      filter: [
        {
          field: "show_flag",
          operator: "is",
          value: "true",
        },
      ],
      orderBy: [{ column: "id", order: "DESC" }],
      first: 10,
      page: 1,
    },
  });

  // const lngArr = data?.languagesForFe?.data.map((language) => ({
  //   value: language?.code,
  //   label: `${mapLanguageName(
  //     language?.name,
  //   )} (${language?.code?.toUpperCase()})`,
  // }));

  const [selectedCode, setSelectedCode] = useState(
    props.selectedLanguage &&
      lngArr?.some((lang) => props?.selectedLanguage?.startsWith(lang.value))
      ? lngArr?.find((lang) => props?.selectedLanguage?.startsWith(lang.value))
          ?.value
      : "en",
  );

  // useEffect(() => {
  //   // Check if selectedLanguage is provided and lngArr is available
  //   if (props.selectedLanguage && lngArr) {
  //     const matchingLanguage = lngArr.find((lang) =>
  //       props.selectedLanguage.startsWith(lang.value),
  //     );

  //     if (matchingLanguage) {
  //       setSelectedCode(matchingLanguage.value);
  //     }
  //   }
  // }, [props.selectedLanguage, lngArr]);

  const changeLanguage = (countryCode, pathName) => {
    let arr = pathName && pathName.pathname ? pathName.pathname.split("/") : [];
    if (arr.length > 1) {
      switch (countryCode) {
        case "my":
          arr[1] = MY_MM;
          break;
        case "zh":
          arr[1] = "zh"; // Update with the correct path for Chinese
          break;
        case "th":
          arr[1] = "th"; // Update with the correct path for Thai
          break;
        default:
          arr[1] = "en";
      }
      i18n
        .changeLanguage(countryCode)
        .then(() => {
          console.log(`Language successfully changed to: ${countryCode}`);
        })
        .catch((err) => {
          console.error("Error changing language:", err);
        });
      window.location = arr.join("/") + pathName.search;
    }
  };

  return (
    <>
      <div className="react-flags-container">
        <MediaQuery minDeviceWidth={781}>
          <img
            width={23}
            height={23}
            src={flags[selectedCode]}
            selected={selectedCode}
            onClick={(e) => {
              setIsOpen(true);
              setAnchorEl(e.currentTarget);
            }}
            alt="mm-mobile-flag"
          />
          <Popper
            open={isOpen}
            anchorEl={anchorEl || null}
            placement="bottom"
            modifiers={{
              flip: {
                enabled: true,
              },
            }}
            className="lngPopper"
          >
            {({ placement }) => (
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <Card className="lngPopperCard">
                  <span className="triangle-up"></span>
                  <Grid container>
                    <Grid item={true}>
                      {lngArr
                        ?.filter((lang) => {
                          return data?.languagesForFe?.data.find(
                            (apiLang) => apiLang.code === lang.value,
                          );
                        })
                        ?.map((val, lngKey) => {
                          return (
                            <div
                              key={lngKey}
                              className="lngRow"
                              onClick={() => {
                                setSelectedCode(val.value);
                                i18n.changeLanguage(val.value);
                                changeLanguage(val.value, location);
                              }}
                            >
                              <img
                                width={20}
                                height={20}
                                src={flags[val.value]}
                                alt="mm-mobile-flag"
                              />
                              <span>{val.label}</span>
                            </div>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Card>
              </ClickAwayListener>
            )}
          </Popper>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={780}>
          <img
            width={23}
            height={23}
            src={flags[selectedCode]}
            onClick={(e) => {
              setIsOpen(true);
              setAnchorEl(e.currentTarget);
            }}
            alt="mm-mobile-flag"
          />
          <Popper
            open={isOpen}
            anchorEl={anchorEl || null}
            placement="bottom"
            modifiers={{
              flip: {
                enabled: true,
              },
            }}
            className="lngPopper"
          >
            {({ placement }) => (
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <Card className="lngPopperCard">
                  <span className="triangle-up"></span>
                  <Grid container>
                    <Grid item={true}>
                      {lngArr
                        ?.filter((lang) => {
                          return data?.languagesForFe?.data.find(
                            (apiLang) => apiLang.code === lang.value,
                          );
                        })
                        ?.map((val, lngKey) => {
                          return (
                            <div
                              key={lngKey}
                              className="lngRow"
                              onClick={(e) => {
                                setSelectedCode(val.value);
                                changeLanguage(val.value, location);
                                i18n.changeLanguage(val.value);
                              }}
                            >
                              <img
                                width={20}
                                height={20}
                                src={flags[val.value]}
                                alt="mm-mobile-flag"
                              />
                              <span>{val.label}</span>
                            </div>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Card>
              </ClickAwayListener>
            )}
          </Popper>
        </MediaQuery>
      </div>
    </>
  );
};

export default ReactFlags;
