import React, { useState, useCallback, useEffect } from "react";
import { Grid, Modal, Button, TextField } from "@mui/material";
import { useDropzone } from "react-dropzone";
import clientV3 from "../../apolloClient";
import clientV3Upload from "../../apolloClientUpload";
import { FILE_UPLOAD, REPORT_FEEDBACK } from "../../queries/paymentquery";
import { remove } from "lodash";
import { sendFeedBackReport } from "../../utils/paymentmutation";
import {
  MOMONEY,
  AYAPAY,
  MPITESAN,
  CITIZENPAY,
  ONEPAY,
  WAVEMONEY,
  UABPAY,
  KBZ,
} from "../../config/inappclients";
import {
  KBZSC_IN_WEB,
  VIBER_IN_WEB,
  CITY_REWARD_IN_WEB,
  NINJA_VAN_IN_WEB,
  UAB_IN_WEB,
  ABC_IN_WEB,
} from "../../config/inwebclients";
import { useTranslation } from "react-i18next";

const FeedbackModal = ({
  showBugReportModal,
  setShowBugReportModal,
  type,
  showConfirmModal,
  loginUserId,
  clientTag,
}) => {
  const [description, setDescription] = useState("");
  const [attachFile, setAttachFile] = useState([]);
  const [errormsg, setErrorMessage] = useState(null);
  const [clickChecker, setClickChecker] = useState(false);
  const { t } = useTranslation(["offlinePayment"]);

  const client =
    clientTag === MPITESAN
      ? 9
      : clientTag === CITIZENPAY
      ? 11
      : clientTag === AYAPAY
      ? 6
      : clientTag === ONEPAY
      ? 8
      : clientTag === MOMONEY
      ? 12
      : clientTag === WAVEMONEY
      ? 7
      : clientTag === UABPAY
      ? 13
      : clientTag === KBZ
      ? 5
      : clientTag === VIBER_IN_WEB
      ? 3
      : clientTag === CITY_REWARD_IN_WEB
      ? 2
      : clientTag === NINJA_VAN_IN_WEB
      ? 18
      : clientTag === UAB_IN_WEB
      ? 17
      : clientTag === ABC_IN_WEB
      ? 16
      : clientTag === KBZSC_IN_WEB
      ? 4
      : 1;

  // if (type) {
  //   type === "flight"
  //     ? (prefill_msg = `Hi, I'm interested in ${
  //         productDetail?.company
  //       } flight (${productDetail?.external_id}) from ${
  //         productDetail?.from
  //       } to ${productDetail?.to} on ${moment(
  //         productDetail?.departure_date,
  //         "YYYY-MM-DD",
  //       ).format("YYYY-MMM-DD")} with starting price of ${
  //         productDetail?.fareClasses[0].or
  //       } ${productDetail?.fareClasses[0].currency}. Can you help me?`)
  //     : type === "noflight"
  //     ? (prefill_msg =
  //         searchInfo?.endDate !== "0"
  //           ? `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} | ${searchInfo.startDate} -> ${searchInfo.endDate}|${searchInfo.tripType}. Can you help me?`
  //           : `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} |${searchInfo.startDate} |${searchInfo.tripType}. Can you help me?`)
  //     : type === "bus"
  //     ? (prefill_msg = `Hi, i'm interested in ${searchInfo?.operator_name_en} | ${searchInfo?.source_name_en} - ${searchInfo?.destination_name_en} | Departure Date/Time: ${searchInfo?.departure_date_time} | Arrival Date/Time: ${searchInfo?.est_arrival_date_time} | ${searchInfo?.fare_breakdowns?.gb} ${searchInfo?.currency}. Can you help me?`)
  //     : type === "hotel"
  //     ? (prefill_msg = searchInfo)
  //     : "";
  // }

  useEffect(() => {
    setErrorMessage(null);
    setDescription("");
    setAttachFile([]);
  }, [showBugReportModal]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setErrorMessage("");
      const file = acceptedFiles;
      clientV3Upload()
        .mutate({
          mutation: FILE_UPLOAD,
          variables: { files: file },
        })
        .then((res) => {
          let files = res.data.upload.map((val) => {
            return {
              id: typeof val === "string" ? null : val,
              filename: typeof val === "string" ? val : val,
            };
          });
          setAttachFile(files);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [setAttachFile],
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    setErrorMessage("Please select only one file");
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
  });

  const sendFeedBack = () => {
    setClickChecker(true);
    sendFeedBackReport(clientV3, REPORT_FEEDBACK, {
      description: description,
      attachments: attachFile,
      causer_id: parseInt(loginUserId) || null,
      client_id: client,
    })
      .then((response) => {
        if (response && response.data && response.data.createFeedback) {
          showConfirmModal && showConfirmModal(false);
          setShowBugReportModal(false);
          setClickChecker(false);
        }
      })
      .catch((error) => {
        showConfirmModal && showConfirmModal(true);
        setShowBugReportModal(false);
        setClickChecker(false);
      });
  };

  return (
    <React.Fragment>
      <Modal
        className="offlineIntMsgModal"
        open={showBugReportModal}
        id="reliefModal"
        onClose={(event, reason) => {
          window.GorgiasChat && window.GorgiasChat.close();
          if (reason === "backdropClick" && reason !== "escapeKeyDown") {
            setShowBugReportModal(false);
          }
        }}
      >
        <Grid
          className="confirmationBox contactUsModal customerContainer"
          style={{
            backgroundColor: "#fff",
            outline: "none",
          }}
          container
        >
          <Grid item sm={12} md={12} xs={12}>
            <p style={infoDescription}>Feedback Report!</p>
          </Grid>
          <Grid item sm={12} md={12} xs={12} style={{ marginBottom: "20px" }}>
            {" "}
            <TextField
              name="description"
              placeholder="Description"
              value={description}
              autoComplete="on"
              onChange={(e) => setDescription(e.target.value)}
              className="descBox"
              multiline
            />
          </Grid>
          <div
            {...getRootProps({
              className: "form-control bugReport",
            })}
          >
            <input {...getInputProps()} />
            <div>
              <i className="fa fa-cloud-upload"></i>{" "}
              {isDragActive
                ? t(`offlinePayment.loading`) !== "offlinePayment.loading"
                  ? t(`offlinePayment.loading`)
                  : "loading..."
                : t(`offlinePayment.upload_file`) !==
                  "offlinePayment.upload_file"
                ? t(`offlinePayment.upload_file`)
                : "Upload file..."}
            </div>
            <span>
              {t(`offlinePayment.max_file_size`) !==
              "offlinePayment.max_file_size"
                ? t(`offlinePayment.max_file_size`)
                : "Max file size:"}{" "}
              1MB
            </span>
          </div>
          {attachFile &&
            attachFile.length > 0 &&
            attachFile.map((file, key) => {
              let filename = file.filename.split("/")[2]
                ? file.filename.split("/")[2]
                : file.filename.split("/")[1];
              return (
                <div className="fileupload bugReport">
                  <React.Fragment key={key}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={file.download_link}
                      key={filename}
                      className="file-name bugReport"
                    >
                      {filename}
                    </a>{" "}
                    <i
                      className="fa fa-close"
                      onClick={() =>
                        setAttachFile(remove(attachFile, (n, k) => key !== k))
                      }
                    ></i>
                  </React.Fragment>
                </div>
              );
            })}
          {errormsg && <span className="errMsg bugReport">{errormsg}</span>}
          <br />
          <Grid item sm={12} md={12} xs={12} style={{ marginTop: "15px" }}>
            <Button
              color="primary"
              variant="contained"
              disabled={
                description === "" ||
                (Array.isArray(attachFile) && attachFile.length === 0) ||
                clickChecker
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  description !== "" &&
                  Array.isArray(attachFile) &&
                  attachFile.length > 0
                ) {
                  sendFeedBack();
                } else {
                  if (
                    description === "" &&
                    Array.isArray(attachFile) &&
                    attachFile.length === 0
                  ) {
                    setErrorMessage(
                      "Please fill description and upload image!",
                    );
                  } else if (
                    Array.isArray(attachFile) &&
                    attachFile.length === 0
                  ) {
                    setErrorMessage("Please upload image!");
                  } else {
                    setErrorMessage("Please fill description!");
                  }
                }
              }}
              className={
                description === "" ||
                (Array.isArray(attachFile) && attachFile.length === 0) ||
                clickChecker
                  ? "btnError bugReport feedbackBtn"
                  : "feedbackBtn"
              }
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

const infoDescription = {
  fontSize: "18px",
  textAlign: "center",
  lineHeight: "22px",
};

export default FeedbackModal;
