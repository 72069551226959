import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  EN_LOCALE,
  LOGIN_PEOPLE_DETAIL,
  LOGIN_USER_ID,
  MM_LOCALE,
  MY_MM,
} from "../../config/constants";
import {
  checkInApp,
  checkInWeb,
  getClientTag,
  getLocale,
} from "../../utils/checkurl";
import { Box, Card, CardContent, Grid } from "@material-ui/core";
import "../../assets/scss/loginusers/Profile.scss";
import Passenger from "../../components/passenger/Passenger";
import "../../assets/scss/loginusers/Passenger.scss";
import LoginSignupModal from "../login/LoginSignupModal";
import { ADDRESSBOOK_BY_ARRANGER } from "../../queries/addressbookquery";
import clientV3 from "../../apolloClient";
import { AddressBookI, BookingHistoryI, InsiderDashI, UserI } from "./SvgIcon";
import SimplePagination from "../../components/SimplePagination";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { VIBER_IN_WEB } from "../../config/inwebclients";
import {
  CITIZENPAY,
  KBZ,
  MPITESAN,
  ONEPAY,
  WAVEMONEY,
} from "../../config/inappclients";
import useFlymyaSessionHook from "../../hooks/FlymyaSessionHook";
import HomeMenu from "../HomeMenu";
import UpDownArrow from "../../assets/images/updownarrow.png";
import DeleteAccountDialog from "./DeleteAccountDialog";
import { Popper, ClickAwayListener } from "@mui/material";
import { getAge, getPassengerType } from "../../utils/addressbookUtils";

const AddressBook = (props) => {
  const params = useParams();
  const [myanmarFont] = useState(
    getLocale(params.locale) === MY_MM ? "myanmarFont" : "",
  );
  const [showMenu, setShowMenu] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("flight");
  const navigate = useNavigate();
  const [selectedLanguage] = useState(getLocale(params.locale));
  const [language] = useState(
    getLocale(params.locale) === MY_MM ? MM_LOCALE : EN_LOCALE,
  );
  const [arrangerData, setArrangerData] = useState(null);
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const [addressbookArr, setAddressbookArr] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDim, setIsDim] = useState(false);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  let passArr = [];
  const { t } = useTranslation(["b2c"]);
  const [clientTag] = useState(getClientTag(window.location.search));
  const [isFilter, setIsFilter] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { handleFlymyaSession } = useFlymyaSessionHook();
  const [anchorEl, setAnchorEl] = useState(null);
  const [addressbookArrOld, setAddressbookArrOld] = useState([]);

  const filterTypeList = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Adult",
    },
    {
      id: 3,
      name: "Child",
    },
    {
      id: 4,
      name: "Infant",
    },
  ];

  const [filterType, setFilterType] = useState(filterTypeList[0]);

  useEffect(() => {
    handleFlymyaSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAddressBooks = useCallback((val, filter, countVal) => {
    let arrangerId = JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL))?.id;

    clientV3()
      .query({
        query: ADDRESSBOOK_BY_ARRANGER,
        fetchPolicy: "network-only",
        variables: {
          filter: [
            { field: "arranger_id", operator: "is", value: String(arrangerId) },
          ],
          orderBy: [{ column: "id", order: "DESC" }],
          first: countVal ? Number(countVal) : Number(count),
          page: Number(val),
        },
      })
      .then((res) => {
        res?.data?.addressBooksByArranger?.data &&
          setAddressbookArr(res?.data?.addressBooksByArranger?.data);
        setAddressbookArrOld(res?.data?.addressBooksByArranger?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getDataFromStoredData = () => {
    let data = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    setArrangerData(JSON.parse(data));
  };

  useEffect(() => {
    const checkLogin = JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL));
    checkLogin && checkLogin.id && getAddressBooks();
    getDataFromStoredData();
  }, []);

  const goToProfile = () => {
    navigate({
      pathname: isInApp
        ? `/${selectedLanguage}/in-app/profile/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : isInWeb
        ? `/${selectedLanguage}/in-web/profile/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : `/${selectedLanguage}/profile/${localStorage.getItem(LOGIN_USER_ID)}`,
      search: window.location.search,
    });
  };

  const handleLinkToBookingHistory = () => {
    navigate({
      pathname: isInApp
        ? `/${selectedLanguage}/in-app/booking-history/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : isInWeb
        ? `/${selectedLanguage}/in-web/booking-history/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : `/${selectedLanguage}/booking-history/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`,
      search: window.location.search,
    });
  };

  const handleLinkToInsiderDashboard = () => {
    navigate({
      pathname: isInApp
        ? `/${selectedLanguage}/in-app/insider-dashboard/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : isInWeb
        ? `/${selectedLanguage}/in-web/insider-dashboard/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : `/${selectedLanguage}/insider-dashboard/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`,
      search: window.location.search,
    });
  };

  for (let i = 0; i < count; i++) {
    passArr.push(<Passenger />);
  }

  const getInAppBgColor = (clientTag) => {
    switch (clientTag) {
      case WAVEMONEY:
        return "#1692C5";
      case KBZ:
        return "#0B55A8";
      case VIBER_IN_WEB:
        return "#794AFF";
      case ONEPAY:
        return "#1FBACD";
      case MPITESAN:
        return "#ec3833";
      case CITIZENPAY:
        return "#ec008c";
      case "mptpay":
        return "#cbcca9";
      case "ayapay":
        return "#cd1f2c";
      case "kbzSC":
        return "#0B55A8";
      case "grab":
        return "#00b14f";
      case "njv":
        return "#be0d2b";
      case "uabb":
        return "#38B449";
      case "uabpay":
        return "#6868ac";
      case "momoney":
        return "#4cb849";
      case window.innerWidth < 780:
        return "#f7f9fe";
      default:
        return "#00aeef";
    }
  };

  return (
    <div className="profileContainer">
      {/* <PageHeader
        pageName={"ProfilePage"}
        selectedLanguage={selectedLanguage}
        pathName={props.location}
        isInApp={isInApp}
        isInWeb={isInWeb}
      /> */}
      <HomeMenu
        props={props}
        isInApp={isInApp}
        isInWeb={window.location.pathname.includes("in-web") ? true : false}
        myanmarFont={myanmarFont}
        selectedLanguage={selectedLanguage}
        clientTag={getClientTag(window.location.search)}
        toggleDrawer={() => setShowMenu(!showMenu)}
        showMenu={showMenu}
        pageName="AboutUs"
        // gorgiasInfo={gorgiasInfo}
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
      />
      <Box mt={3}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <div className="tagStyle">
              <div
                className="secsubTagStyle"
                onClick={goToProfile}
                style={{ cursor: "pointer" }}
              >
                <UserI
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  fill="black"
                />
                {isMobile ? <span>Profile</span> : <span>User Profile</span>}
              </div>
              <div
                className="subTagStyle"
                style={{
                  backgroundColor:
                    window.innerWidth < 780
                      ? "transparent"
                      : getInAppBgColor(clientTag),
                  cursor: "pointer",
                }}
              >
                <AddressBookI
                  fill={
                    window.innerWidth < 780
                      ? getInAppBgColor(clientTag)
                      : "white"
                  }
                />
                <span
                  style={{
                    color:
                      window.innerWidth < 780
                        ? getInAppBgColor(clientTag)
                        : "white",
                  }}
                >
                  Address Book
                </span>
              </div>
              <div
                className="secsubTagStyle"
                onClick={handleLinkToBookingHistory}
                style={{ cursor: "pointer" }}
              >
                <BookingHistoryI fill="black" />
                {isMobile ? <span>History</span> : <span>Booking History</span>}
              </div>
              <div
                className="secsubTagStyle"
                onClick={handleLinkToInsiderDashboard}
                style={{ cursor: "pointer" }}
              >
                <InsiderDashI fill="black" />
                <span>Insider Dashboard</span>
              </div>
            </div>
            <Card className="card">
              <CardContent>
                {/* <div className="addressBookContainer">
                  <span style={{ fontSize: "24px" }}>
                    {t(`b2c.address_book`) !== "b2c.address_book"
                      ? t(`b2c.address_book`)
                      : "Address Book"}
                  </span>
                </div> */}
                {/* <div
                  style={{ marginTop: "15px" }}
                  className="userProfileContainer"
                >
                  <div className="profileRedirect">
                    <span className="userProfile">
                      {t(`b2c.user_profile`) !== "b2c.user_profile"
                        ? t(`b2c.user_profile`)
                        : "User Profile"}
                    </span>
                    <div className="arrangerContainer" onClick={goToProfile}>
                      <div className="profileName">
                        {arrangerData?.prefix}
                        {arrangerData?.first_name} {arrangerData?.last_name}
                      </div>
                      <div className="arranger" style={{ marginRight: "0px" }}>
                        {t(`b2c.arranger`) !== "b2c.arranger"
                          ? t(`b2c.arranger`)
                          : "Arranger"}
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div style={{ marginLeft: "48px", marginTop: "10px" }}>
                  <span
                    onClick={handleLinkToBookingHistory}
                    style={{ color: "#00aeef", cursor: "pointer" }}
                  >
                    {t(`b2c.booking_history`) !== "b2c.booking_history"
                      ? t(`b2c.booking_history`)
                      : "Booking History"}
                  </span>
                  <span> | </span>
                  <span
                    onClick={handleLinkToInsiderDashboard}
                    style={{ color: "#00aeef", cursor: "pointer" }}
                  >
                    {" "}
                    {t(`b2c.insider_dashboard`) !== "b2c.insider_dashboard"
                      ? t(`b2c.insider_dashboard`)
                      : "Insider Dashboard"}
                  </span>
                </div> */}
                <div className="passengerListContainer">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className="passengersList">
                      {t(`b2c.passenger_list`) !== "b2c.passenger_list"
                        ? t(`b2c.passenger_list`)
                        : "Passengers List"}
                    </span>
                    {/* <div className="count">
                      {addressbookArr?.length}/{PASSENGER_MAX_COUNT}
                    </div> */}
                    <div
                      className="count"
                      onClick={() => {
                        setIsShow(true);
                        setCount(count + 1);
                      }}
                    >
                      <span
                        style={{
                          color: getInAppBgColor(clientTag),
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        {"Add New Passenger"}{" "}
                      </span>
                      <div
                        size="small"
                        className="addPass"
                        style={{ backgroundColor: getInAppBgColor(clientTag) }}
                      >
                        <i className="fa fa-plus " aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>

                  <span className="addPpl">
                    {"Add people you travel often with."}
                  </span>

                  <div
                    className="countmobile"
                    onClick={() => {
                      setIsShow(true);
                      setCount(count + 1);
                    }}
                  >
                    <span
                      style={{
                        color: getInAppBgColor(clientTag),
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      {"Add New Passenger"}{" "}
                    </span>
                    <div
                      size="small"
                      style={{ backgroundColor: getInAppBgColor(clientTag) }}
                      className="addPass"
                    >
                      <i className="fa fa-plus " aria-hidden="true"></i>
                    </div>
                  </div>

                  <div
                    className="filterType"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setIsFilter(!isFilter);
                    }}
                  >
                    <span className="filterLabel">{filterType?.name}</span>
                    <img src={UpDownArrow} width={8} height={10} />

                    <Popper
                      id={1}
                      key={1}
                      className={"policy" + 1}
                      open={isFilter}
                      anchorEl={anchorEl}
                      placement={"bottom-start"}
                    >
                      {({ placement }) => (
                        <ClickAwayListener
                          onClickAway={() => setIsFilter(false)}
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              borderRadius: "4px",
                              boxShadow: "0px 2px 5px #474747",
                            }}
                          >
                            {filterTypeList?.map((item_) => {
                              return (
                                <div
                                  style={{
                                    backgroundColor:
                                      filterType?.id === item_?.id
                                        ? "#B7ECFF"
                                        : "white",
                                    display: "flex",
                                    padding: "10px 35px 10px 15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let tempArr = [...addressbookArrOld];
                                    let filterTempArr = tempArr?.filter(
                                      (temp_) => {
                                        let age = getAge(temp_?.dob);
                                        let passengerType =
                                          getPassengerType(age);
                                        if (item_?.name === "All") {
                                          return temp_;
                                        }
                                        if (passengerType === item_?.name) {
                                          return temp_;
                                        }
                                      },
                                    );
                                    setAddressbookArr(filterTempArr);
                                    setFilterType(item_);
                                  }}
                                >
                                  <span
                                    style={{
                                      color:
                                        filterType?.id === item_?.id
                                          ? "#00AEEF"
                                          : "black",
                                      fontSize: 14,
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {item_?.name}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </ClickAwayListener>
                      )}
                    </Popper>
                  </div>
                  <div className={"listDiv scrollDiv"}>
                    {addressbookArr &&
                      addressbookArr.map((add) => {
                        return (
                          <Passenger
                            key={add?.id}
                            addBook={add}
                            getInAppBgColor={getInAppBgColor}
                            setOpenDelete={setOpenDelete}
                            setIsSpinner={setIsSpinner}
                            isDim={isDim}
                            setIsDim={setIsDim}
                            loginEmail={loginEmail}
                            setLoginEmail={setLoginEmail}
                            loginPassword={loginPassword}
                            setLoginPassword={setLoginPassword}
                            errorMsg={errorMsg}
                            setErrorMsg={setErrorMsg}
                            isForget={isForget}
                            setIsForget={setIsForget}
                            isSpinner={isSpinner}
                          />
                        );
                      })}
                  </div>
                  {/* {addressbookArr?.length < 5 && (
                    <div className="addPplBtn">
                      <Button
                        fullWidth
                        style={{
                          background: "#00aeef",
                          color: "#fff",
                          textTransform: "capitalize",
                          fontSize: "18px",
                        }}
                        variant="contained"
                        onClick={() => {
                          setIsShow(true);
                          setCount(count + 1);
                        }}
                      >
                        {t(`b2c.add_passenger`) !== "b2c.add_passenger"
                          ? t(`b2c.add_passenger`)
                          : "Add Passengers"}
                      </Button>
                    </div>
                  )} */}
                </div>
                <div className="pagi-region" style={{ marginBottom: 0 }}>
                  <SimplePagination
                    page={page}
                    setPage={(val) => {
                      setPage(val);
                      getAddressBooks(val, []);
                    }}
                    handleSizePerPagePagination={(sizePerPage) => {
                      setCount(sizePerPage);
                      getAddressBooks(page, [], sizePerPage);
                    }}
                    count={count}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <DeleteAccountDialog
        page="addressBook"
        open={openDelete}
        setOpen={setOpenDelete}
      />
      <LoginSignupModal
        isInApp={isInApp}
        addId="addressBookCreate"
        // data={data}
        isShow={isShow}
        setIsShow={() => setIsShow(false)}
        setIsSpinner={setIsSpinner}
        isDim={isDim}
        setIsDim={setIsDim}
        loginEmail={loginEmail}
        setLoginEmail={setLoginEmail}
        loginPassword={loginPassword}
        setLoginPassword={setLoginPassword}
        // handleLogin={handleLogin}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        isForget={isForget}
        setIsForget={setIsForget}
        isSpinner={isSpinner}
        // setIsCheck={setIsCheck}
        selectedLanguage={selectedLanguage}
        // clientTag={clientTag}
        page="addressBook"
      />
    </div>
  );
};

export default AddressBook;
