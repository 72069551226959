import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { MYANMAR_ID, YANGON_REGION } from "../../config/constants";
import { COUNTRIES } from "../../config/data/v3countries";
import { DivisionList, CardList } from "../../config/data/nrcList";
import {
  DayOption,
  MonthOption,
  YearOption,
} from "../../utils/reactselectUtils/dobUtils";
import { CustomSelectStyles } from "./ReactSelectCustomCss";

const MoreInformations = ({
  pageName,
  values,
  setFieldValue,
  date,
  selectedDivision,
  setSelectedDivison,
  setDefaultList,
  chooseList,
  selectedList,
  setSelectedList,
  handleNrc,
  selectedCardList,
  selectedNrcNo,
  listArr,
  setSelectedCardList,
  setCheckNRC,
  setSelectedNrcNo,
  checkNRC,
  touched,
  errors,
  getCity,
  getTownship,
  regionArr,
  cityArr,
  setCityArr,
  townshipArr,
  setTownshipArr,
}) => {
  const { t } = useTranslation(["masterPassengerInformation", "b2c"]);

  return (
    <Grid item={true} xs={12} sm={12} md={12} className="signup-more-div">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <p style={{ color: "black" }}>
            {t(`b2c.more_info_optional`, { ns: "b2c" }) !==
            "b2c.more_info_optional"
              ? t(`b2c.more_info_optional`, { ns: "b2c" })
              : "More Information (Optional)"}
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {pageName !== "addressBook" && (
              <Grid item={true} xs={12} sm={12} md={6} className="dobRegion">
                <label>
                  {t("masterPassengerInformation.dob") !==
                  "masterPassengerInformation.dob"
                    ? t("masterPassengerInformation.dob") + " *"
                    : "Date of Birth *"}
                </label>
                <div container className="dobSelects">
                  <Grid item={true} xs={4} sm={4} md={4}>
                    <Select
                      name="signupDobDay"
                      options={DayOption(
                        values.signupDobMonth === "02" ? 29 : 31,
                      )}
                      value={{
                        value:
                          values.signupDobDay === "00"
                            ? "Day"
                            : values.signupDobDay,
                        label:
                          values.signupDobDay === "00"
                            ? "Day"
                            : values.signupDobDay,
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => setFieldValue("signupDobDay", e.value)}
                      className={
                        values.signupDobDay === "00"
                          ? "selectBox placeHolderText"
                          : "selectBox"
                      }
                      styles={CustomSelectStyles}
                      placeholder="Day"
                      isDisabled={date}
                    />
                  </Grid>
                  <Grid item={true} xs={4} sm={4} md={4}>
                    <Select
                      name="signupDobMonth"
                      options={MonthOption()}
                      value={{
                        value:
                          values.signupDobMonth === "00"
                            ? "Month"
                            : values.signupDobMonth,
                        label:
                          values.signupDobMonth === "00"
                            ? "Month"
                            : values.signupDobMonth,
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => setFieldValue("signupDobMonth", e.value)}
                      className={
                        values.signupDobMonth === "00"
                          ? "selectBox placeHolderText"
                          : "selectBox"
                      }
                      styles={CustomSelectStyles}
                      placeholder="Month"
                      isDisabled={date}
                    />
                  </Grid>
                  <Grid item={true} xs={4} sm={4} md={4}>
                    <Select
                      name="signupDobYear"
                      options={YearOption("dob")}
                      value={{
                        value:
                          values.signupDobYear === "0000"
                            ? "Year"
                            : values.signupDobYear,
                        label:
                          values.signupDobYear === "0000"
                            ? "Year"
                            : values.signupDobYear,
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => setFieldValue("signupDobYear", e.value)}
                      className={
                        values.signupDobYear === "0000"
                          ? "selectBox placeHolderText"
                          : "selectBox"
                      }
                      styles={CustomSelectStyles}
                      placeholder="Year"
                      isDisabled={date}
                    />
                  </Grid>
                </div>
              </Grid>
            )}

            {pageName !== "addressBook" && (
              <Grid
                item={true}
                sm={12}
                md={pageName === "addressBook" ? 8 : 6}
                className="nationality-grid"
              >
                <label>
                  {t(`b2c.nationality`, { ns: "b2c" }) !== "b2c.nationality"
                    ? t(`b2c.nationality`, { ns: "b2c" }) + " *"
                    : "Nationality *"}
                </label>
                <Select
                  name="signupNationality"
                  options={COUNTRIES.map((country, i) => {
                    return {
                      value: country.CountryId,
                      label: country.CountryName,
                    };
                  })}
                  value={{
                    value: values.signupNationality,
                    label: COUNTRIES.find(
                      (val) =>
                        Number(val.CountryId) ===
                        Number(values.signupNationality),
                    ).label,
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFieldValue("signupNationality", e.value);
                  }}
                  className={"nationality"}
                  styles={CustomSelectStyles}
                  placeholder="Nationality"
                  isDisabled={date}
                />
              </Grid>
            )}

            {Number(values.signupNationality) === MYANMAR_ID &&
              pageName !== "addressBook" && (
                <Grid item={true} xs={12} sm={12} md={12} className="nrcRegion">
                  <label>
                    {t(`b2c.nrc`, { ns: "b2c" }) !== "b2c.nrc"
                      ? t(`b2c.nrc`, { ns: "b2c" })
                      : "NRC"}
                  </label>
                  <div container className="nrcSelects">
                    <Grid item={true} xs={3} sm={3} md={1}>
                      <Select
                        name="nrcDivision"
                        options={DivisionList.map((division) => {
                          return {
                            value: division,
                            label: division,
                          };
                        })}
                        value={{
                          value: selectedDivision,
                          label: selectedDivision,
                        }}
                        defaultValue={{
                          value: 5,
                          label: 5,
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          setSelectedDivison(e.value);
                          setDefaultList(e.value);
                          chooseList(e.value);
                        }}
                        className={"selectBox nrcNumberField"}
                        styles={CustomSelectStyles}
                        placeholder=""
                        isDisabled={date}
                      />
                    </Grid>
                    <Grid item={true} xs={5} sm={5} md={4}>
                      <Select
                        name="nrcList"
                        options={
                          listArr
                            ? listArr.map((list) => {
                                return {
                                  value: list,
                                  label: list,
                                };
                              })
                            : []
                        }
                        value={{
                          value: selectedList,
                          label: selectedList,
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          setSelectedList(e.value);
                          handleNrc(
                            selectedDivision,
                            e.value,
                            selectedCardList,
                            selectedNrcNo,
                          );
                        }}
                        className={"selectBox nrcTextField"}
                        styles={CustomSelectStyles}
                        placeholder=""
                        isDisabled={date}
                      />
                    </Grid>
                    <Grid item={true} xs={4} sm={4} md={1}>
                      <Select
                        name="nrcCard"
                        options={CardList.map((card) => {
                          return {
                            value: card,
                            label: card,
                          };
                        })}
                        value={{
                          value: selectedCardList,
                          label: selectedCardList,
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          setSelectedCardList(e.value);
                          handleNrc(
                            selectedDivision,
                            selectedList,
                            e.value,
                            selectedNrcNo,
                          );
                        }}
                        className={"selectBox nrcNumberField"}
                        styles={CustomSelectStyles}
                        placeholder=""
                        isDisabled={date}
                      />
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={6}>
                      <input
                        name="nrcNumber"
                        disabled={date}
                        className="nrcNumber"
                        value={selectedNrcNo}
                        autoComplete="off"
                        onChange={(e) => {
                          if (!/^[0-9\b]*$/.test(e.target.value)) {
                            setCheckNRC(true);
                            return;
                          }
                          setCheckNRC(false);
                          setSelectedNrcNo(e.target.value);
                          handleNrc(
                            selectedDivision,
                            selectedList,
                            selectedCardList,
                            e.target.value,
                          );
                        }}
                        data-lpignore="true"
                      />
                      {checkNRC && (
                        <p className="signup-error">Please type only number.</p>
                      )}
                    </Grid>
                  </div>
                </Grid>
              )}

            {pageName !== "addressBook" && (
              <>
                <Grid item={true} xs={12} sm={6} md={6} className="nrcRegion">
                  <label>
                    {t(`b2c.passport`, { ns: "b2c" }) !== "b2c.passport"
                      ? t(`b2c.passport`, { ns: "b2c" })
                      : "Passport"}
                  </label>
                  <Grid item={true} sm={12} md={12}>
                    <input
                      className={
                        touched.signupNrcPass && errors.signupNrcPass
                          ? "error-textbox passNumber"
                          : "passNumber"
                      }
                      name="signupNrcPass"
                      disabled={date}
                      type="text"
                      value={values.signupNrcPass}
                      autoComplete="off"
                      onChange={(e) =>
                        setFieldValue("signupNrcPass", e.target.value)
                      }
                      data-lpignore="true"
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} className="nrcRegion">
                  <label>
                    {t(`b2c.passport_expiration_date`, { ns: "b2c" }) !==
                    "b2c.passport_expiration_date"
                      ? t(`b2c.passport_expiration_date`, { ns: "b2c" })
                      : "Passport Expiration Date"}
                  </label>
                  <Grid container className="passSelects">
                    <Grid item={true} xs={4} sm={4} md={4}>
                      <Select
                        name="signupExpDay"
                        options={DayOption(
                          values.signupExpMonth === "02" ? 29 : 31,
                        )}
                        value={{
                          value:
                            values.signupExpDay === "00"
                              ? "Day"
                              : values.signupExpDay,
                          label:
                            values.signupExpDay === "00"
                              ? "Day"
                              : values.signupExpDay,
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => setFieldValue("signupExpDay", e.value)}
                        className={
                          values.signupExpDay === "00"
                            ? "selectBox placeHolderText"
                            : "selectBox"
                        }
                        styles={CustomSelectStyles}
                        placeholder="Day"
                        isDisabled={date}
                      />
                    </Grid>
                    <Grid item={true} xs={4} sm={4} md={4}>
                      <Select
                        name="signupExpMonth"
                        options={MonthOption()}
                        value={{
                          value:
                            values.signupExpMonth === "00"
                              ? "Month"
                              : values.signupExpMonth,
                          label:
                            values.signupExpMonth === "00"
                              ? "Month"
                              : values.signupExpMonth,
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) =>
                          setFieldValue("signupExpMonth", e.value)
                        }
                        className={
                          values.signupExpMonth === "00"
                            ? "selectBox placeHolderText"
                            : "selectBox"
                        }
                        styles={CustomSelectStyles}
                        placeholder="Month"
                        isDisabled={date}
                      />
                    </Grid>
                    <Grid item={true} xs={4} sm={4} md={4}>
                      <Select
                        name="signupExpYear"
                        options={YearOption("exp")}
                        value={{
                          value:
                            values.signupExpYear === "0000"
                              ? "Year"
                              : values.signupExpYear,
                          label:
                            values.signupExpYear === "0000"
                              ? "Year"
                              : values.signupExpYear,
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) =>
                          setFieldValue("signupExpYear", e.value)
                        }
                        className={
                          values.signupExpYear === "0000"
                            ? "selectBox placeHolderText"
                            : "selectBox"
                        }
                        styles={CustomSelectStyles}
                        placeholder="Year"
                        isDisabled={date}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {Number(values.signupNationality) === MYANMAR_ID && (
              <>
                <Grid item={true} xs={6} sm={6} md={4}>
                  <label>
                    {t(`b2c.region`, { ns: "b2c" }) !== "b2c.region"
                      ? t(`b2c.region`, { ns: "b2c" })
                      : "Region"}
                  </label>
                  <Select
                    name="region"
                    options={regionArr}
                    value={{
                      value: values.region,
                      label:
                        regionArr.length > 0
                          ? regionArr.find(
                              (val) =>
                                Number(val.value) === Number(values.region),
                            ).label
                          : "Yangon",
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setFieldValue("region", e.value);
                      setFieldValue("city", null);
                      setCityArr([]);
                      getCity({
                        variables: {
                          first: 30,
                          page: 1,
                          filter: [
                            {
                              field: "region_id",
                              operator: "=",
                              value: String(e.value),
                            },
                          ],
                        },
                      });
                    }}
                    className="selectBox regionSelect"
                    styles={CustomSelectStyles}
                    placeholder=""
                    isDisabled={date}
                  />
                </Grid>

                <Grid item={true} xs={6} sm={6} md={4}>
                  <label>
                    {t(`b2c.city`, { ns: "b2c" }) !== "b2c.city"
                      ? t(`b2c.city`, { ns: "b2c" })
                      : "City"}
                  </label>
                  <Select
                    name="city"
                    options={cityArr}
                    value={{
                      value: values.city,
                      label:
                        cityArr.length > 0
                          ? cityArr.find(
                              (val) =>
                                Number(val.value) === Number(values.city),
                            )
                            ? cityArr.find(
                                (val) =>
                                  Number(val.value) === Number(values.city),
                              ).label
                            : ""
                          : values.region === YANGON_REGION
                          ? "Yangon"
                          : "",
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setFieldValue("city", e.value);
                      setFieldValue("township", null);
                      setTownshipArr([]);
                      getTownship({
                        variables: {
                          first: 30,
                          page: 1,
                          filter: [
                            {
                              field: "city_id",
                              operator: "=",
                              value: String(e.value),
                            },
                          ],
                        },
                      });
                    }}
                    className="selectBox regionSelect"
                    styles={CustomSelectStyles}
                    placeholder=""
                    isDisabled={date}
                  />
                </Grid>

                <Grid item={true} xs={12} sm={12} md={4}>
                  <label>
                    {t(`b2c.township`, { ns: "b2c" }) !== "b2c.township"
                      ? t(`b2c.township`, { ns: "b2c" })
                      : "Township"}
                  </label>
                  <Select
                    name="township"
                    options={townshipArr}
                    value={{
                      value: values.township,
                      label:
                        !values.township && townshipArr.length > 0
                          ? townshipArr.find(
                              (val) =>
                                Number(val.value) === Number(values.township),
                            )
                            ? townshipArr.find(
                                (val) =>
                                  Number(val.value) === Number(values.township),
                              ).label
                            : ""
                          : "",
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => setFieldValue("township", e.value)}
                    className="selectBox regionSelect"
                    styles={CustomSelectStyles}
                    placeholder=""
                    isDisabled={date}
                  />
                </Grid>
              </>
            )}
            <Grid item={true} xs={12} sm={12} md={12}>
              <label>
                {t(`b2c.address_details`, { ns: "b2c" }) !==
                "b2c.address_details"
                  ? t(`b2c.address_details`, { ns: "b2c" })
                  : "Address Details"}
              </label>
              <textarea
                name="address"
                disabled={date}
                className="address"
                value={values.address}
                autoComplete="off"
                onChange={(e) => setFieldValue("address", e.target.value)}
              ></textarea>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default MoreInformations;
