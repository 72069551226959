import React, { useState, useRef, useEffect } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import NumericInputComponent from "../../components/inputs/NumericInputComponent";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { useTranslation } from "react-i18next";

const HomePassengerTypeWeb = ({ params, handleValueChange, pageType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [infant, setInfant] = useState(0);
  const [room, setRoom] = useState(0);
  const { t } = useTranslation([
    "masterHomeSecondPage",
    "masterHomePage",
    "hotelTwo",
    "hotel",
  ]);

  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current && params) {
      params && setAdult(params.adult ? params.adult : 1);
      params && setChild(params.child ? params.child : 0);
      params && setInfant(params.infant ? params.infant : 0);
      params &&
        (pageType === "hotelSearch" || pageType === "hotelDetail") &&
        setRoom(params.room ? params.room : 0);

      mounted.current = true;
    }
  }, [params, pageType]);

  const handleChange = (name) => (value) => {
    if (name && name[0]) {
      name[0] === "adult" && setAdult(value);
      name[0] === "child" && setChild(value);
      name[0] === "infant" && setInfant(value);
      (pageType === "hotelSearch" || pageType === "hotelDetail") &&
        name[0] === "room" &&
        setRoom(value);
      handleValueChange(value, name[0]);
    }
  };

  let combine = Number(adult) + Number(child) + Number(infant);

  return (
    <>
      <div
        className={`passengerType ${pageType === "hotelSearch" ? "hotel" : ""}`}
        id="choosePassengerType"
        onClick={(e) => {
          setIsOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      >
        {pageType !== "hotelDetail" && (
          <span>
            {pageType !== "hotelSearch" ? (
              <>
                {t("masterHomePage.passenger", {
                  ns: "masterHomePage",
                }) !== "masterHomePage.passenger"
                  ? t("masterHomePage.passenger", {
                      ns: "masterHomePage",
                    })
                  : "Passenger"}{" "}
                :{" "}
              </>
            ) : (
              <>
                {t("hotel.guest", { ns: "hotel" }) !== "hotel.guest"
                  ? t("hotel.guest", { ns: "hotel" })
                  : "Guest"}{" "}
              </>
            )}
          </span>
        )}
        <span>
          {adult + " "}
          <span>
            {adult > 1
              ? (t("masterHomePage.adult", {
                  ns: "masterHomePage",
                }) !== "masterHomePage.adult"
                  ? t("masterHomePage.adult", {
                      ns: "masterHomePage",
                    })
                  : "Adult") +
                (t("masterHomePage.s", {
                  ns: "masterHomePage",
                }) !== "masterHomePage.s"
                  ? t("masterHomePage.s", {
                      ns: "masterHomePage",
                    })
                  : "s")
              : t("masterHomePage.adult", {
                  ns: "masterHomePage",
                }) !== "masterHomePage.adult"
              ? t("masterHomePage.adult", {
                  ns: "masterHomePage",
                })
              : "Adult"}
          </span>

          <span>
            {child > 0 &&
              ", " +
                child +
                (child > 1
                  ? " " +
                    (t("masterHomePage.children", {
                      ns: "masterHomePage",
                    }) !== "masterHomePage.children"
                      ? t("masterHomePage.children", {
                          ns: "masterHomePage",
                        })
                      : "Children")
                  : " " +
                    (t("masterHomePage.child", {
                      ns: "masterHomePage",
                    }) !== "masterHomePage.child"
                      ? t("masterHomePage.child", {
                          ns: "masterHomePage",
                        })
                      : "Child"))}
          </span>

          <span>
            {infant > 0 &&
              ", " +
                infant +
                " " +
                (infant > 1
                  ? (t("masterHomePage.infant", {
                      ns: "masterHomePage",
                    }) !== "masterHomePage.infant"
                      ? t("masterHomePage.infant", {
                          ns: "masterHomePage",
                        })
                      : "Infant") +
                    (t("masterHomePage.s", {
                      ns: "masterHomePage",
                    }) !== "masterHomePage.s"
                      ? t("masterHomePage.s", {
                          ns: "masterHomePage",
                        })
                      : "s")
                  : t("masterHomePage.infant", {
                      ns: "masterHomePage",
                    }) !== "masterHomePage.infant"
                  ? t("masterHomePage.infant", {
                      ns: "masterHomePage",
                    })
                  : "Infant")}
          </span>
          {/* {pageType !== "hotelSearch" && (
            <span>
              {infant > 0 &&
                ", " +
                  infant +
                  " " +
                  (infant > 1
                    ? (t("masterHomePage.infant") !== "masterHomePage.infant"
                        ? t("masterHomePage.infant")
                        : "Infant") +
                      (t("masterHomePage.s") !== "masterHomePage.s"
                        ? t("masterHomePage.s")
                        : "s")
                    : t("masterHomePage.infant") !== "masterHomePage.infant"
                    ? t("masterHomePage.infant")
                    : "Infant")}
            </span>
          )} */}
          {(pageType === "hotelSearch" || pageType === "hotelDetail") && (
            <span>
              {room > 0 &&
                ", " +
                  room +
                  " " +
                  (room > 1
                    ? (t("hotelTwo.room", { ns: "hotelTwo" }) !==
                      "hotelTwo.room"
                        ? t("hotelTwo.room", { ns: "hotelTwo" })
                        : "Room") +
                      (t("masterHomePage.s", {
                        ns: "masterHomePage",
                      }) !== "masterHomePage.s"
                        ? t("masterHomePage.s", {
                            ns: "masterHomePage",
                          })
                        : "s")
                    : t("hotelTwo.room", { ns: "hotelTwo" }) !== "hotelTwo.room"
                    ? t("hotelTwo.room", { ns: "hotelTwo" })
                    : "Room")}
            </span>
          )}
        </span>
        <div className="down-arrow-img"></div>
      </div>
      <Popper
        open={isOpen}
        anchorEl={anchorEl || null}
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: true,
          },

          offset: {
            top: "30px",
          },
        }}
        className={`passengerTooltip ${
          pageType === "hotelDetail" && "hotel-detail"
        }`}
      >
        {({ placement }) => (
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Card className="pasengerTypeCard">
              <Grid container>
                <Grid item={true} sm={7}>
                  <div className="ageSelect">
                    <span className="ageType">
                      {t("masterHomePage.adult", {
                        ns: "masterHomePage",
                      }) !== "masterHomePage.adult"
                        ? t("masterHomePage.adult", {
                            ns: "masterHomePage",
                          })
                        : "Adult"}
                    </span>
                    <br />
                    <span className="ageLimit">
                      (
                      {t("masterHomePage.adult_age_description", {
                        ns: "masterHomePage",
                      }) !== "masterHomePage.adult_age_description"
                        ? t("masterHomePage.adult_age_description", {
                            ns: "masterHomePage",
                          })
                        : "Age 12 and over"}
                      )
                    </span>
                  </div>
                </Grid>
                <Grid item={true} sm={5}>
                  <div className="homeChooseAge">
                    <NumericInputComponent
                      min={1}
                      max={combine === 9 ? adult : 9}
                      value={adult}
                      onChange={handleChange}
                      inputName="adult"
                    />
                  </div>
                </Grid>
                <Grid item={true} sm={7}>
                  <div className="ageSelect">
                    <span className="ageType">
                      {t("masterHomePage.child", {
                        ns: "masterHomePage",
                      }) !== "masterHomePage.child"
                        ? t("masterHomePage.child", {
                            ns: "masterHomePage",
                          })
                        : "Child"}
                    </span>
                    <br />
                    <span className="ageLimit">
                      (
                      {t("masterHomePage.child_age_description", {
                        ns: "masterHomePage",
                      }) !== "masterHomePage.child_age_description"
                        ? t("masterHomePage.child_age_description", {
                            ns: "masterHomePage",
                          })
                        : "Age 2-11"}
                      )
                    </span>
                  </div>
                </Grid>
                <Grid item={true} sm={5}>
                  <div className="homeChooseAge">
                    <NumericInputComponent
                      min={0}
                      max={combine === 9 && Number(child) !== 0 ? child : 9}
                      value={child}
                      onChange={handleChange}
                      inputName="child"
                      disabled={
                        combine >= 9 && Number(child) === 0 ? true : false
                      }
                    />
                  </div>
                </Grid>
                {pageType !== "hotelSearch" && pageType !== "hotelDetail" && (
                  <>
                    <Grid item={true} sm={7}>
                      <div className="ageSelect">
                        <span className="ageType">
                          {t("masterHomePage.infant", {
                            ns: "masterHomePage",
                          }) !== "masterHomePage.infant"
                            ? t("masterHomePage.infant", {
                                ns: "masterHomePage",
                              })
                            : "Infant"}
                        </span>
                        <br />
                        <span className="ageLimit">
                          (
                          {t("masterHomePage.infant_age_description", {
                            ns: "masterHomePage",
                          }) !== "masterHomePage.infant_age_description"
                            ? t("masterHomePage.infant_age_description", {
                                ns: "masterHomePage",
                              })
                            : "Below Age 2"}
                          )
                        </span>
                      </div>
                    </Grid>
                    <Grid item={true} sm={5}>
                      <div className="homeChooseAge">
                        <NumericInputComponent
                          min={0}
                          max={
                            combine === 9 &&
                            Number(infant) < Number(adult) &&
                            Number(infant) !== 0
                              ? Number(infant)
                              : Number(adult)
                          }
                          value={infant}
                          onChange={handleChange}
                          inputName="infant"
                          disabled={
                            combine >= 9 && Number(infant) === 0 ? true : false
                          }
                        />
                      </div>
                    </Grid>
                    {combine >= 9 && (
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#6d8494",
                          marginTop: "8px",
                        }}
                      >
                        {t("masterHomeSecondPage.passenger_contact_message") !==
                        "masterHomeSecondPage.passenger_contact_message"
                          ? t("masterHomeSecondPage.passenger_contact_message")
                          : "Please contact us for group booking at better price!"}
                      </span>
                    )}
                  </>
                )}
                {(pageType === "hotelSearch" || pageType === "hotelDetail") && (
                  <>
                    <Grid item={true} sm={7}>
                      <div className="ageSelect">
                        <span className="ageType">
                          {t("hotelTwo.room", { ns: "hotelTwo" }) !==
                          "hotelTwo.room"
                            ? t("hotelTwo.room", { ns: "hotelTwo" })
                            : "Room"}
                        </span>
                        <br />
                        <span className="ageLimit">
                          (
                          {t("hotelTwo.all_room_types", { ns: "hotelTwo" }) !==
                          "hotelTwo.all_room_types"
                            ? t("hotelTwo.all_room_types", { ns: "hotelTwo" })
                            : "All room types"}
                          )
                        </span>
                      </div>
                    </Grid>
                    <Grid item={true} sm={5}>
                      <div className="homeChooseAge">
                        <NumericInputComponent
                          min={1}
                          max={30}
                          value={room}
                          onChange={handleChange}
                          inputName="room"
                        />
                      </div>
                    </Grid>
                    {/* {combine >= 9 && (
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#6d8494",
                          marginTop: "8px",
                        }}
                      >
                        {t("masterHomeSecondPage.passenger_contact_message") !==
                        "masterHomeSecondPage.passenger_contact_message"
                          ? t("masterHomeSecondPage.passenger_contact_message")
                          : "Please contact us for group booking at better price!"}
                      </span>
                    )} */}
                  </>
                )}
              </Grid>
            </Card>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default HomePassengerTypeWeb;
